import {SidebarState} from "./types";

const initialState: SidebarState = {
    isSidebarOpen: true,

};

export function sidebarReducer(
    state = initialState,
    action: any
): SidebarState {
    switch (action.type) {
        case 'SET_SIDEBAR_OPEN':
            return {
                ...state,
                isSidebarOpen: action.payload
            };
        case 'SET_TOAST_MESSAGE':
            return {
                ...state,
                emitToast: action.payload
            }
        default:
            return state;
    }
}
