import React from "react";
import {Practice, PracticeOp} from "../../../redux/operation/types";
import moment from "moment";
import {getHHMMFromSeconds, getStatusOfOperation} from "../../../redux/operation/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEye} from "@fortawesome/free-solid-svg-icons";

interface StateProps {
    practices: PracticeOp[]
    goToPractice: (id: number) => void
}

const TablePractice = (props: StateProps) => {
    return (
        <table className="table table-bordered table-hover table-striped">
            <thead>
            <tr>
                <th>Id</th>
                <th>Attività</th>
                <th>Utente</th>
                <th>Denominazione</th>
                <th>Data creazione</th>
                <th>Tempo accumulato</th>
                <th>Stato</th>
                <th>Azioni</th>
            </tr>
            </thead>
            <tbody>
            {props.practices.length === 0 && (
                <tr>
                    <td colSpan={8} className="font-weight-bold text-center">
                        Nessun intervento disponibile
                    </td>
                </tr>
            )}
            {props.practices.length > 0 && props.practices.map(practice => (
                <tr key={`practice_${practice.id}`}>
                    <th scope="col">{practice.id}</th>
                    <td>{practice.activity.progressive_association} {practice.activity.association.name}</td>
                    <td>{practice.user.name} {practice.user.surname}</td>
                    <td>{practice.denomination}</td>
                    <td>{moment(practice.createdAt).format('DD-MM-YYYY HH:MM')}</td>
                    <td>{getHHMMFromSeconds(practice.accumulated_seconds)}</td>
                    <td>{getStatusOfOperation(practice.closed)}</td>
                    <td>
                        <FontAwesomeIcon icon={faEye} onClick={() => props.goToPractice(practice.id)}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default TablePractice