import React from "react";
import Sidebar from "../components/layout/SideBar";
import {useParams, useHistory} from "react-router-dom";
import {EventConsultation, GeneralInformation, OperationType, Practice, Tutoring} from "../../redux/operation/types";
import {
    fetchEventConsultation,
    fetchGeneralInformation, fetchOperation,
    fetchPractice,
    fetchTutoring
} from "../../redux/operation/actions";
import Loader from "../components/loader/Loader";
import GeneralInformationView from "./singleOperation/GeneralInformationView";
import EventConsultationView from "./singleOperation/EventConsultationView";
import TutoringView from "./singleOperation/TutoringView";
import PracticeView from "./singleOperation/PracticeView";
import withAuth from "../utils/WithAuth";

interface OwnState {
    data: {
        data?: GeneralInformation | EventConsultation | Practice | Tutoring
        isFetching: boolean
        id: number
        type: OperationType
    }
}


const ViewOperation = () => {
    const history = useHistory()
    const params: any = useParams()
    const [state, setState] = React.useState<OwnState>({
        data: {
            type: params.type,
            id: params.id,
            isFetching: false
        }
    })
    React.useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        if (!state.data.isFetching) {
            setState({
                data: {
                    ...state.data,
                    isFetching: true
                }
            })
            try {
                let data
                data = await fetchOperation(state.data.id, state.data.type)
                setState({
                    data: {
                        ...state.data,
                        isFetching: false,
                        data
                    }
                })
            } catch (e) {

            }
        }
    }

    console.log(state)

    if (state.data.isFetching)
        return (
            <Sidebar activeMenu="OPERAZIONI">
                <Loader />
            </Sidebar>
        )
    else
        return (
            <Sidebar activeMenu="OPERAZIONI">
                {state.data.type === "GENERAL_INFORMATION" && state.data.data && (
                    <GeneralInformationView data={state.data.data as GeneralInformation} />
                )}
                {state.data.type === "EVENT_CONSULTATION" && state.data.data && (
                    <EventConsultationView data={state.data.data as EventConsultation} />
                )}
                {state.data.type === "TUTORING" && state.data.data && (
                    <TutoringView data={state.data.data as Tutoring} />
                )}
                {state.data.type === "PRACTICE" && state.data.data && (
                    <PracticeView data={state.data.data as Practice} />
                )}
            </Sidebar>
        )
}

export default withAuth(ViewOperation)