import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Association from "./pages/association/Association";
import CreateAssociation from "./pages/association/CreateAssociation";
import ViewAssociation from "./pages/association/ViewAssociation";
import Activity from "./pages/activity/Activity";
import CreateActivity from "./pages/activity/CreateActivity";
import Operation from "./pages/operations/Operation";
import CreateOperation from "./pages/operations/CreateOperation";
import ViewOperation from "./pages/operations/ViewOperation";
import User from "./pages/user/User";
import Environments from "./pages/association/Environments";
import UpdateAssociation from "./pages/association/UpdateAssociation";
import {SidebarState} from "./redux/layout/types";
import {AppState} from "./redux";
import {connect, ConnectedProps} from "react-redux";
import {setToastMessage} from "./redux/layout/actions";
import {toast, ToastContainer} from "react-toastify";
import ViewActivity from "./pages/activity/ViewActivity";
import UpdateOperation from "./pages/operations/UpdateOperation";

interface Props {
    sidebar: SidebarState
}

const mapState = (state: AppState) => ({
    sidebar: state.sidebar,
});

const connector = connect(
    mapState,
    {
        setToastMessage: () => setToastMessage(undefined)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>


function App(props: PropsFromRedux) {
    React.useEffect(() => {
        if (props.sidebar.emitToast !== undefined){
            toast(props.sidebar.emitToast.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: props.sidebar.emitToast.type
            });
            props.setToastMessage()
        }
    }, [props.sidebar.emitToast])
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/associazioni" exact>
                        <Association />
                    </Route>
                    <Route path="/associazioni/new" exact>
                        <CreateAssociation />
                    </Route>
                    <Route path="/associazioni/:id" exact>
                        <ViewAssociation />
                    </Route>
                    <Route path="/associazioni/update/:id" exact>
                        <UpdateAssociation />
                    </Route>
                    <Route path="/ambiti" exact>
                        <Environments />
                    </Route>
                    <Route path="/attivita" exact>
                        <Activity />
                    </Route>
                    <Route path="/attivita/new" exact>
                        <CreateActivity />
                    </Route>
                    <Route path="/attivita/:id" exact>
                        <ViewActivity />
                    </Route>
                    <Route path="/operazioni" exact>
                        <Operation />
                    </Route>
                    <Route path="/operazioni/new" exact>
                        <CreateOperation />
                    </Route>
                    <Route path="/operazioni/:id/:type" exact>
                        <ViewOperation />
                    </Route>
                    <Route path="/operazioni/update/:id/:type" exact>
                        <UpdateOperation />
                    </Route>
                    <Route path="/utenti" exact>
                        <User />
                    </Route>
                </Switch>
                <ToastContainer />
            </div>
        </Router>
    );
}

export default connector(App)