import React from "react";
import Sidebar from "../components/layout/SideBar";
import Loader from "../components/loader/Loader";
import {useParams, useHistory} from "react-router-dom";
import {Association} from "../../redux/association/types";
import {deleteAssociation, fetchAssocation} from "../../redux/association/actions";
import withAuth from "../utils/WithAuth";

interface OwnState {
    association?: Association
    isFetching: boolean
}

const ViewAssociation = () => {
    const [state, setState] = React.useState<OwnState>({
        isFetching: true
    })
    const params: any = useParams()
    const history: any = useHistory()
    React.useEffect(() => {
        fetchAssocation(params.id)
            .then(data => {
                setState({
                    ...state,
                    association: data,
                    isFetching: false
                })
            })
            .catch(e => {
                console.log(e)
            })
    }, [])

    const goToUpdateAssociation = () => {
        if (state.association){
            history.push(`/associazioni/update/${state.association.id}`)
        }
    }

    const deleteAssociationHandler = () => {
        if (state.association) {
            deleteAssociation(state.association.id)
                .then(() => {
                    history.push('/associazioni')
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }

    return (
        <Sidebar activeMenu={"ASSOCIAZIONI"}>
            {state.isFetching && (
                <Loader/>
            )}
            {!state.isFetching && state.association && (
                <>
                    <div className="row mb-5">
                        <div className="col-md-9">

                        </div>
                        <div className="col-md-3">
                            {/*
                            <button
                                onClick={deleteAssociationHandler}
                                className="btn btn-danger">
                                ELIMINA
                            </button>

                            */}
                            <button
                                onClick={goToUpdateAssociation}
                                className="btn btn-primary float-right">
                                MODIFICA
                            </button>
                        </div>
                    </div>
                    <table className="table table-bordered table-striped">
                        <tbody>
                        <tr>
                            <th>
                                Denominazione
                            </th>
                            <td>
                                {state.association.name}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ambito
                            </th>
                            <td>
                                {state.association.association_environment.value}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                E-mail
                            </th>
                            <td>
                                {state.association.email}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                C.F.
                            </th>
                            <td>
                                {state.association.fiscal_code}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                P. IVA
                            </th>
                            <td>
                                {state.association.vat}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Telefono
                            </th>
                            <td>
                                {state.association.telephone}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Cellulare
                            </th>
                            <td>
                                {state.association.phone}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Indirizzo
                            </th>
                            <td>
                                {state.association.address}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Comune
                            </th>
                            <td>
                                {state.association.municipality}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                CAP
                            </th>
                            <td>
                                {state.association.cap}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Provincia
                            </th>
                            <td>
                                {state.association.province}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Sito
                            </th>
                            <td>
                                {state.association.site}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Facebook
                            </th>
                            <td>
                                {state.association.facebook}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Instagram
                            </th>
                            <td>
                                {state.association.instagram}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Presidente
                            </th>
                            <td>
                                {state.association.president}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Segretario
                            </th>
                            <td>
                                {state.association.segretary}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {state.association.association_referents && state.association.association_referents.length > 0 && (
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th colSpan={5} className="text-center">REFERENTI</th>
                            </tr>
                            <tr>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Email</th>
                                <th>Telefono</th>
                                <th>Ruolo</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.association.association_referents.map(referent => (
                                <tr key={`referen_${referent.id}`}>
                                    <td>{referent.name}</td>
                                    <td>{referent.surname}</td>
                                    <td>{referent.email}</td>
                                    <td>{referent.phone}</td>
                                    <td>{referent.role}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </>
            )}
        </Sidebar>
    )

}

export default withAuth(ViewAssociation)