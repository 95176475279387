import React from "react";
import {User} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {useHistory} from "react-router-dom";
import Sidebar from "../components/layout/SideBar";
import withAuth from "../utils/WithAuth";
import {fetchOperations, getHHMMFromSeconds} from "../../redux/operation/actions";
import {OperationsResponse, OperationType} from "../../redux/operation/types";
import Loader from "../components/loader/Loader";
import TableGeneralInformation from "./tables/TableGeneraInformation";
import TableEventConsultation from "./tables/TableEventConsultation";
import TablePractice from "./tables/TablePractice";
import TableTutoring from "./tables/TableTutoring";

interface OwnState {
    data: {
        isFetching: boolean
        operationResponse?: OperationsResponse
        activeTab: OperationType
    }
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>

const Operation = (props: PropsFromRedux) => {
    const history = useHistory()
    const [state, setState] = React.useState<OwnState>({
        data: {
            isFetching: false,
            activeTab: "GENERAL_INFORMATION"
        }
    })
    React.useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setState({
            data: {
                ...state.data,
                isFetching: true
            }
        })
        fetchOperations()
            .then(data => setState({
                data: {
                    ...state.data,
                    operationResponse: data
                }
            }))
            .catch(e => {
                console.log(e)
            })
    }

    const createOperation = () => {
        history.push('/operazioni/new')
    }

    const handleChangeTab = (activeTab: OperationType) => {
        setState({
            data: {
                ...state.data,
                activeTab
            }
        })
    }

    const goToDetail = (id: number, type: OperationType) => {
        history.push(`/operazioni/${id}/${type}`)
    }

    // TODO FIX TMP
    const totalTimeGeneralInformations = state.data.operationResponse && state.data.operationResponse.generalInformations.reduce((acc, curr) => acc+curr.accumulated_seconds,0)
    const totalTimeEventConsultations = state.data.operationResponse && state.data.operationResponse.eventConsultations.reduce((acc, curr) => acc+curr.accumulated_seconds,0)
    const totalTimePractices = state.data.operationResponse && state.data.operationResponse.practices.reduce((acc, curr) => acc+curr.accumulated_seconds,0)
    const totalTimeTutoring = state.data.operationResponse && state.data.operationResponse.tutorings.reduce((acc, curr) => acc+curr.accumulated_seconds,0)

    return (
        <Sidebar activeMenu="OPERAZIONI">
            <div className="row mb-5">
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    <button
                        onClick={createOperation}
                        className="btn btn-primary float-right">
                        CREA INTERVENTO
                    </button>
                </div>
            </div>
            {state.data.isFetching && (
                <Loader/>
            )}
            {!state.data.isFetching && state.data.operationResponse && (
                <>
                    <nav>
                        <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            <a
                                className={`nav-item nav-link ${state.data.activeTab === "GENERAL_INFORMATION" && 'active'}`}
                                onClick={() => handleChangeTab("GENERAL_INFORMATION")}
                            >
                                INFORMAZIONI GENERALI <b>({state.data.operationResponse.generalInformations.length})</b>
                                {totalTimeGeneralInformations !== undefined && (
                                    <p className="font-weight-bold small">Totale tempo accumulato: {getHHMMFromSeconds(totalTimeGeneralInformations)}</p>
                                )}
                            </a>
                            <a
                                className={`nav-item nav-link ${state.data.activeTab === "EVENT_CONSULTATION" && 'active'}`}
                                onClick={() => handleChangeTab("EVENT_CONSULTATION")}
                            >
                                CONSULENZA EVENTO <b>({state.data.operationResponse.eventConsultations.length})</b>
                                {totalTimeEventConsultations !== undefined && (
                                    <p className="font-weight-bold small">Totale tempo accumulato: {getHHMMFromSeconds(totalTimeEventConsultations)}</p>
                                )}
                            </a>
                            <a
                                className={`nav-item nav-link ${state.data.activeTab === "TUTORING" && 'active'}`}
                                onClick={() => handleChangeTab("TUTORING")}
                            >
                                TUTORING <b>({state.data.operationResponse.tutorings.length})</b>
                                {totalTimeTutoring !== undefined && (
                                    <p className="font-weight-bold small">Totale tempo accumulato: {getHHMMFromSeconds(totalTimeTutoring)}</p>
                                )}
                            </a>
                            <a
                                className={`nav-item nav-link ${state.data.activeTab === "PRACTICE" && 'active'}`}
                                onClick={() => handleChangeTab("PRACTICE")}
                            >
                                PRATICA <b>({state.data.operationResponse.practices.length})</b>
                                {totalTimePractices !== undefined && (
                                    <p className="font-weight-bold small">Totale tempo accumulato: {getHHMMFromSeconds(totalTimePractices)}</p>
                                )}
                            </a>
                        </div>
                    </nav>
                    {state.data.activeTab === "GENERAL_INFORMATION" && state.data.operationResponse.generalInformations && (
                        <TableGeneralInformation
                            goToGeneralInformation={id => goToDetail(id, "GENERAL_INFORMATION")}
                            generalInformations={state.data.operationResponse.generalInformations}
                        />
                    )}
                    {state.data.activeTab === "EVENT_CONSULTATION" && state.data.operationResponse.eventConsultations && (
                        <TableEventConsultation
                            goToEventConsultation={id => goToDetail(id, "EVENT_CONSULTATION")}
                            eventConsultations={state.data.operationResponse.eventConsultations}
                        />
                    )}
                    {state.data.activeTab === "PRACTICE" && state.data.operationResponse.practices && (
                        <TablePractice
                            goToPractice={id => goToDetail(id, "PRACTICE")}
                            practices={state.data.operationResponse.practices}
                        />
                    )}
                    {state.data.activeTab === "TUTORING" && state.data.operationResponse.tutorings && (
                        <TableTutoring
                            goToTutoring={id => goToDetail(id, "TUTORING")}
                            tutorings={state.data.operationResponse.tutorings}
                        />
                    )}
                </>
            )}
        </Sidebar>
    )
}

export default withAuth(connector(Operation))