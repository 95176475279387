import React from "react";
import {User} from "../../redux/user/types";
import Sidebar from "../components/layout/SideBar";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../redux";
import withAuth from "../utils/WithAuth";
import FormAssociation from "./FormAssociation";
import "./_index.scss"
import {AssociationEnvironment, AssociationFormData} from "../../redux/association/types";
import {createAssociation, fetchAssociationEnvironments} from "../../redux/association/actions";
import { useHistory } from "react-router-dom";
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>


const CreateAssociation = (props: PropsFromRedux) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [environments, setEnvironments] = React.useState<AssociationEnvironment[]>([])
    React.useEffect(() => {
        fetchAssociationEnvironments()
            .then(response => setEnvironments(response))
            .catch(e => {
                console.log(e)
            })
    }, [])
    const history = useHistory()
    const onSubmit = (data: AssociationFormData) => {
        setIsSubmitting(true)
        createAssociation(data)
            .then(response => {
                setIsSubmitting(false)
                props.setToastMessage({
                    type: "success",
                    message: `L'associazione ${data.name} è stata creata!`
                })
                history.push(`/associazioni/${response.id}`)
            })
            .catch(e => console.log(e))

    }

    return (
        <Sidebar activeMenu={"ASSOCIAZIONI"}>
            <h3 className="text-center mb-4">CREA ASSOCIAZIONE</h3>
            {environments.length === 0 && (
                <h6 className="text-center text-danger">
                    Non è stato creato alcun ambito. Per creare un'associazione bisogna creare almeno un ambito.
                </h6>
            )}
            {environments.length > 0 && (
                <FormAssociation
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    environments={environments}
                    data={{
                        association_referents: [],
                        email: '',
                        name: '',
                        environment: environments[0].id
                    }}
                />
            )}
        </Sidebar>
    )
};

export default withAuth(connector(CreateAssociation));