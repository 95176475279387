import React from "react";
import {UserCreation} from "../../redux/user/types";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

interface Props {
    isSubmitting: boolean
    closeModal: () => void
    onSubmit: (data: UserCreation) => void
}

const ModalCreateUser = (props: Props) => {
    const [user, setUser] = React.useState<UserCreation>({
        email: '',
        name: '',
        roles: [],
        surname: '',
        password: ''
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleChangeRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {value} = e.target
        setUser({
            ...user,
            roles: [value]
        })
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        props.onSubmit(user)
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Modal.Header closeButton>
                Crea un nuovo utente
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Nome <abbr className="text-danger">*</abbr></label>
                            <input
                                className="form-control"
                                value={user.name}
                                required={true}
                                name="name"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cognome <abbr className="text-danger">*</abbr></label>
                            <input
                                className="form-control"
                                value={user.surname}
                                required={true}
                                name="surname"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email <abbr className="text-danger">*</abbr></label>
                            <input
                                className="form-control"
                                value={user.email}
                                required={true}
                                name="email"
                                type="email"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Ruolo <abbr className="text-danger">*</abbr></label>
                            <select
                                value={user.roles.length > 0 && user.roles[0] || ''}
                                onChange={handleChangeRole}
                                className="form-control"
                                required={true}
                            >
                                <option value={""}>-</option>
                                <option value={"admin"}>Admin</option>
                                <option value={"user"}>User</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Password (Se non impostata corrisponde all'E-mail)</label>
                            <input
                                className="form-control"
                                value={user.password}
                                name="password"
                                type="password"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" type="button" onClick={event => props.closeModal()}>
                        Annulla
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>
                        {props.isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                        ) : "Conferma"}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalCreateUser