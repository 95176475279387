import React from "react";
import {User} from "../../redux/user/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

interface StateProps {
    user: User
    isSubmitting: boolean
    onSubmit: (newPassword: string, oldPassword: string) => void
}

interface OwnState {
    oldPassword: string,
    password: string,
    confirmPassword: string,
}

const initialState: OwnState = {
    oldPassword: '', password: '', confirmPassword: ''
}

const UpdateUserPassword = (props: StateProps) => {
    const [state, setState] = React.useState<OwnState>(initialState)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const resetData = () => {
        setState(initialState)
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        props.onSubmit(state.password, state.oldPassword)
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Vecchia password <abbr className="text-danger">*</abbr></label>
                    <InputPassword
                        value={state.oldPassword}
                        name="oldPassword"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Password <abbr className="text-danger">*</abbr></label>
                    <InputPassword
                        value={state.password}
                        name="password"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Conferma password <abbr className="text-danger">*</abbr></label>
                    <InputPassword
                        value={state.confirmPassword}
                        name="confirmPassword"
                        onChange={handleInputChange}
                    />
                    {state.password && state.confirmPassword && state.password !== state.confirmPassword && (
                        <span className="text-danger">Le password non combaciano!</span>
                    )}
                </div>
            </div>
            <div className="row justify-content-end">
                <button className="btn btn-danger" type="button" onClick={resetData} disabled={props.isSubmitting}>
                    Reset
                </button>
                <button className="btn btn-primary ml-2" type="submit" disabled={
                    props.isSubmitting || !state.oldPassword || !state.password || state.password !== state.confirmPassword
                }>
                    {props.isSubmitting ? (
                        <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                    ) : "Conferma"}
                </button>
            </div>
        </form>
    )
}

interface PropsInputPassword {
    value: string,
    name: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputPassword = (props: PropsInputPassword) => {
    const [showPassword, setShowPassword] = React.useState<boolean>(false)

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className="input-group">
            <input
                className="form-control"
                value={props.value}
                type={showPassword && 'text' || 'password'}
                name={props.name}
                onChange={props.onChange}
            />
            <div className="input-group-prepend">
                <span className="input-group-text">
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}
                                                     onClick={handleShowPassword}/>
                </span>
            </div>
        </div>
    )
}

export default UpdateUserPassword