import React from "react";
import {isValidTimeHHMMInput} from "../../redux/operation/actions";

interface Props {
    value: string
    updateValue: (data: string) => void
}

const TimeInput = (props: Props) => {
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        props.updateValue(value)
    }

    return (
        <input
            className="form-control"
            value={props.value || '00:00'}
            onChange={onChangeHandler}
            type="tel"
        />
    )
}

export default TimeInput