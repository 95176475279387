import {User, UserCreation, UserSample} from "../user/types";
import {Association} from "../association/types";
import {
    EventConsultation,
    EventConsultationOp,
    GeneralInformation,
    GeneralInformationOp,
    Practice, PracticeOp,
    Tutoring, TutoringOp
} from "../operation/types";
import {act} from "react-dom/test-utils";

export interface ActivityInterface {
    id: number,
    name: string,
    progressive: number,
    association_id: number,
    contact_date: string,
    contact_tipology: string,
    link_prevalence: string[],
    district: string,
    reference_suap: string,
    progressive_association: number,
    createdAt: string,
    updatedAt: string,
    tutorings: Tutoring[],
    association: Association,
    general_informations: GeneralInformation[],
    event_consultations: EventConsultation[],
    practices: Practice[],
    user: UserSample
}

export interface ActivityFormData {
    association_id: number | undefined
    name: string
    progressive: number | undefined
    contact_tipology: string
    link_prevalence: string[]
    district: string
    contact_date: string
    reference_suap: string
}

export interface ActivityListResponse {
    id: number
    name: string
    progressive: number
    contact_date: string
    contact_tipology: string
    link_prevalence: string[]
    progressive_association: number
    district: string
    reference_suap: string
    createdAt: string
    updatedAt: string
    user: UserCreation
    association: any
    logged_time_general_information: number
    logged_time_event_consultation: number
    logged_time_tutoring: number
    logged_time_practices: number
    logged_total_time: number
}

export interface ActivityListSimple {
    id: number
    name: string
    progressive: number
    association: {id: number, name: string}
    progressive_association: number
}

export interface ActivityFilters {
    name?: string
    contact_date?: string
    district?: string
    association?: number
}

export class ActivityClass{
    association: Association;
    association_id: number; 
    contact_date: string;
    contact_tipology: string;
    createdAt: string;
    district: string;
    event_consultations: EventConsultationOp[];
    general_informations: GeneralInformationOp[];
    id: number;
    name: string;
    progressive: number;
    link_prevalence: string[];
    practices: PracticeOp[];
    progressive_association: number;
    reference_suap: string;
    tutorings: TutoringOp[];
    updatedAt: string;
    user: UserSample;
    totalTimeGeneralInformations: number;
    totalTimeEventConsultations: number;
    totalTimeTutorings: number;
    totalTimePractices: number
    totalTime: number
    constructor(activity: ActivityInterface) {
        this.association = activity.association
        this.association_id = activity.association_id
        this.contact_date = activity.contact_date
        this.contact_tipology = activity.contact_tipology
        this.createdAt = activity.createdAt
        this.district = activity.district
        this.event_consultations = activity.event_consultations.map(entCons => new EventConsultationOp({
            ...entCons,
            activity: activity
        }))
        this.general_informations = activity.general_informations.map(gn => new GeneralInformationOp({
            ...gn,
            activity: activity
        }))
        this.practices = activity.practices.map(practice => new PracticeOp({
            ...practice,
            activity: activity
        }))
        this.tutorings = activity.tutorings.map(tutoring => new TutoringOp({
            ...tutoring,
            activity: activity
        }))
        this.id = activity.id
        this.name = activity.name
        this.progressive = activity.progressive
        this.link_prevalence = activity.link_prevalence
        this.progressive_association = activity.progressive_association
        this.reference_suap = activity.reference_suap
        this.updatedAt = activity.updatedAt
        this.user = activity.user
        this.totalTimeGeneralInformations = this.general_informations.reduce((acc, curr) => acc+curr.accumulated_seconds, 0)
        this.totalTimeEventConsultations = this.event_consultations.reduce((acc, curr) => acc+curr.accumulated_seconds, 0)
        this.totalTimeTutorings = this.tutorings.reduce((acc, curr) => acc+curr.accumulated_seconds, 0)
        this.totalTimePractices = this.practices.reduce((acc, curr) => acc+curr.accumulated_seconds, 0)
        this.totalTime = this.totalTimeTutorings+this.totalTimePractices+this.totalTimeEventConsultations+this.totalTimeGeneralInformations
    }
}