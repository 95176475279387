import React from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {OperationsSimpleResponse, OperationType} from "../../../redux/operation/types";
import Loader from "../loader/Loader";
import {fetchOperationsSimple} from "../../../redux/operation/actions";

interface StateProps {
    close: (eliminate?: boolean) => void
    onSubmit: (id?: number, type?: OperationType) => void
}

interface UpdateOperationState {
    type: OperationType
    id?: number
}

const ModalCloseTimer = (props: StateProps) => {
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [data, setData] = React.useState<OperationsSimpleResponse>()
    const [updateOperation, setUpdateOperation] = React.useState<UpdateOperationState>({
        type: 'GENERAL_INFORMATION'
    })
    React.useEffect(() => {
        loadData()
    }, [])

    const updateOperationType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUpdateOperation({
            id: undefined,
            type: event.target.value as OperationType
        })
    }

    const updateOperationId = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUpdateOperation({
            ...updateOperation,
            id: parseInt(event.target.value, 10)
        })
    }

    const loadData = () => {
        if (!isFetching) {
            setIsFetching(true)
            fetchOperationsSimple()
                .then(response => {
                    setData(response)
                })
                .catch(e => {

                })
                .finally(() => {
                    setIsFetching(false)
                })
        }
    }

    const confirmUpdateOperation = () => {
        props.onSubmit(updateOperation.id, updateOperation.type)
    }

    return (
        <Modal show={true} onHide={props.close} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Concludi timer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isFetching && <Loader/>}
                {!isFetching && data && (
                    <>
                        <h5 className="text-center">Assegna tempo ad un intervento esistente</h5>
                        <div className="row align-items-end">
                            <div className="col-md-4 form-group mb-0">
                                <label className="font-weight-bold">Tipologia</label>
                                <select
                                    className="form-control"
                                    value={updateOperation.type}
                                    onChange={updateOperationType}
                                >
                                    <option value="GENERAL_INFORMATION">Informazioni Generali</option>
                                    <option value="EVENT_CONSULTATION">Consultazione Evento</option>
                                    <option value="PRACTICE">Pratica</option>
                                    <option value="TUTORING">Tutoring</option>
                                </select>
                            </div>
                            <div className="col-md-6 form-group mb-0">
                                <label className="font-weight-bold">Scegli l'intervento</label>
                                <select
                                    className="form-control"
                                    value={updateOperation.id || ''}
                                    onChange={updateOperationId}
                                >
                                    <option value=''>-</option>
                                    {updateOperation.type === "GENERAL_INFORMATION" && data.generalInformations && data.generalInformations.map(gn => (
                                        <option value={gn.id} key={`gn_${gn.id}`}>id: {gn.id},
                                            attività: {gn.activity.progressive_association} {gn.activity.association.name}</option>
                                    ))}
                                    {updateOperation.type === "EVENT_CONSULTATION" && data.eventConsultations && data.eventConsultations.map(ec => (
                                        <option value={ec.id} key={`ec_${ec.id}`}>id: {ec.id},
                                            attività: {ec.activity.progressive_association} {ec.activity.association.name}</option>
                                    ))}
                                    {updateOperation.type === "TUTORING" && data.tutorings && data.tutorings.map(tu => (
                                        <option value={tu.id} key={`tu_${tu.id}`}>id: {tu.id},
                                            attività: {tu.activity.progressive_association} {tu.activity.association.name}</option>
                                    ))}
                                    {updateOperation.type === "PRACTICE" && data.practices && data.practices.map(pr => (
                                        <option value={pr.id} key={`pr_${pr.id}`}>id: {pr.id},
                                            attività: {pr.activity.progressive_association} {pr.activity.association.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2 ">
                                <button
                                    className="btn btn-primary"
                                    disabled={!updateOperation.type || !updateOperation.id}
                                    onClick={confirmUpdateOperation}>
                                    CONFERMA
                                </button>
                            </div>
                        </div>
                        <hr />
                    </>
                )}
                {!isFetching && (
                    <div className="row justify-content-center">
                        <button className="btn btn-primary" onClick={() => props.onSubmit()}>Crea nuovo intervento</button>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" type="button" onClick={e => props.close()}>
                    Annulla
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCloseTimer