import React from "react";
import {User} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import Sidebar from "../components/layout/SideBar";
import withAuth from "../utils/WithAuth";
import FormActivity from "./FormActivity";
import {District} from "../../redux/district/types";
import {fetchDistricts} from "../../redux/district/actions";
import Loader from "../components/loader/Loader";
import {fetchAssociations} from "../../redux/association/actions";
import {Association, AssociationFormData} from "../../redux/association/types";
import {createActivity} from "../../redux/activity/actions";
import {ActivityFormData} from "../../redux/activity/types";
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";
import { useHistory } from "react-router-dom";

interface OwnState {
    isFetching: boolean
    districts: District[]
    associations: Association[]
    isSubmitting: boolean
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>

const initialState: OwnState = {
    isFetching: false,
    isSubmitting: false,
    districts: [],
    associations: []
}

const CreateActivity = (props: PropsFromRedux) => {
    const [state, setState] = React.useState<OwnState>(initialState)
    const history = useHistory()
    React.useEffect(() => {
        setState({
            ...state,
            isFetching: true
        })
        Promise.all([
            fetchDistricts(),
            fetchAssociations()
        ])
            .then(([districts, associations]) => {
                setState({
                    ...state,
                    associations,
                    districts,
                    isFetching: false
                })
            })
            .catch(e=> {
                console.log(e)
            })
    }, [])

    const onSubmit = (data: ActivityFormData) => {
        setState({
            ...state,
            isSubmitting: true
        })
        createActivity(data)
            .then(response => {
                    props.setToastMessage({
                        message: `L'attività è stata creata con successo!`,
                        type: 'success'
                    })
                    history.push(`/attivita/${response.id}`)
            })
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <Sidebar activeMenu="ATTIVITA">
            <h3 className="text-center mb-4">CREA ATTIVITÀ</h3>
            {state.isFetching && (
                <Loader />
            )}
            {!state.isFetching && state.associations.length === 0 && (
                <h6 className="text-center text-danger">
                    Non è stata creata alcuna associazione. Per creare un'attività è neccessaria un'associazione.
                </h6>
            )}
            {!state.isFetching && state.associations.length > 0 && (
                <FormActivity
                    districts={state.districts}
                    associations={state.associations}
                    isSubmitting={state.isSubmitting}
                    onSubmit={onSubmit}
                />
            )}
        </Sidebar>
    )
}

export default withAuth(connector(CreateActivity))