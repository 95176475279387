interface ErrorResponse extends Response {
  statusCode: number;
  message: string;
  errors?: { [key: string]: string[] };
}

interface GoodResponse {
  statusCode: number;
  json?: {};
  text?: {};
}

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
/* -Prod: 95.217.2.129 */
const API_URL = isLocalhost
  ? "http://localhost:3001/api"
  : "http://unpli-be.fvglab.it/api";

export const fetchWrapper = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  headers?: {},
  body?: object
) => {
  let token: string | null = sessionStorage.getItem("BearerToken");
  let headersReq: {} = {};
  if (token !== null) {
    headersReq = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Request-Headers": "*",
      "Access-Control-Expose-Headers": "*",
      ...headers,
    };
  } else {
    headersReq = {
      "Access-Control-Request-Headers": "*",
      "Access-Control-Expose-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      ...headers,
    };
  }
  let response = await fetch(
    API_URL + url,
    method === "GET"
      ? {
          method: "GET",
          headers: headersReq,
          mode: "cors",
        }
      : {
          method: method,
          headers: headersReq,
          body: JSON.stringify(body),
          //    mode: "cors",
        }
  );

  if (response.ok) {
    sessionStorage.setItem("BearerToken", response.headers.get("jwt")!!);
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      let resp: GoodResponse = {
        statusCode: response.status,
        json: await response.json(),
      };
      return resp;
    } else {
      let resp: GoodResponse = {
        statusCode: response.status,
        text: await response.text(),
      };
      return resp;
    }
  } else if (response.status !== 401) {
    const errorResponse: ErrorResponse = await response.json();
    throw errorResponse;
  } else {
    sessionStorage.removeItem("BearerToken");
    const errorResponse: ErrorResponse = await response.json();
    throw errorResponse;
  }
};
