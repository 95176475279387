import {
    EventConsultation, EventConsultationOp,
    GeneralInformation, GeneralInformationOp,
    HistoryLogCreation, OperationsResponse, OperationsSimpleResponse,
    OperationType,
    Practice, PracticeOp,
    Tutoring, TutoringOp
} from "./types";
import {fetchWrapper} from "../../network/fetchWrapper";
import moment from "moment";

export const createOperation = (type: OperationType, operation: any, historyLog: HistoryLogCreation): Promise<any> => {
    return fetchWrapper(`/operation/${type}`, "POST", {
        'Content-Type': 'application/json'
    }, {
        operation,
        historyLog
    })
        .then(response => response.json)
        .catch(e => {
            throw e
        })
}

export const updateOperation = (type: OperationType, id: number, operation: any, historyLog: HistoryLogCreation): Promise<any> => {
    return fetchWrapper(`/operation/${type}/${id}`, "PUT", {
        'Content-Type': 'application/json'
    }, {
        operation,
        historyLog
    })
        .then(response => response.json)
        .catch(e => {
            throw e
        })
}

export const fetchOperations = (): Promise<OperationsResponse> => {
    return fetchWrapper('/operation', 'GET')
        .then((data: any) => ({
            generalInformations: (data.json.generalInformations as GeneralInformation[]).map(gn => new GeneralInformationOp(gn)),
            eventConsultations: (data.json.eventConsultations as EventConsultation[]).map(eventConsultation => new EventConsultationOp(eventConsultation)),
            practices: (data.json.practices as Practice[]).map(practice => new PracticeOp(practice)),
            tutorings: (data.json.tutorings as Tutoring[]).map(tutoring => new TutoringOp(tutoring))
        }))
        .catch(e => {
            throw e
        })
}

export const fetchOperationsSimple = (): Promise<OperationsSimpleResponse> => {
    return fetchWrapper('/operation/simple', 'GET')
        .then(data => data.json as OperationsSimpleResponse)
        .catch(e => {
            throw e
        })
}

export const fetchOperation = (id: number, type: OperationType) => {
    switch (type) {
        case "GENERAL_INFORMATION":
            return fetchGeneralInformation(id)
        case "EVENT_CONSULTATION":
            return  fetchEventConsultation(id)
        case "PRACTICE":
            return  fetchPractice(id)
        case "TUTORING":
            return fetchTutoring(id)
        default:
            throw new Error('Intervento invalido')
    }
}

export const fetchGeneralInformation = (id: number): Promise<GeneralInformation> => {
    return fetchWrapper(`/operation/general-information/${id}`, "GET")
        .then(data => data.json as GeneralInformation)
        .catch(e => {
            throw e
        })
}

export const fetchEventConsultation = (id: number): Promise<EventConsultation> => {
    return fetchWrapper(`/operation/event-consultation/${id}`, "GET")
        .then(data => data.json as EventConsultation)
        .catch(e => {
            throw e
        })
}

export const fetchTutoring = (id: number): Promise<Tutoring | any> => {
    return fetchWrapper(`/operation/tutoring/${id}`, "GET")
        .then(data => data.json)
        .catch(e => {
            throw e
        })
}

export const fetchPractice = (id: number): Promise<Practice | any> => {
    return fetchWrapper(`/operation/practice/${id}`, "GET")
        .then(data => data.json)
        .catch(e => {
            throw e
        })
}

export const getHHMMFromSeconds = (seconds: number = 0): string => {
    var hh = Math.floor(seconds / 3600);
    var mm = Math.floor((seconds % 3600) / 60);
    return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}`
}

export const getStatusOfOperation = (state: boolean) => {
    return !state ? 'Aperta' : 'Conclusa'
}

export const isValidTimeHHMMInput = (val: string) => {

    if (!val.includes(':')){
        return false
    }

    const [hoursStr, minutesStr] = val.split(':');

    if (!val.match(/^\d+\:\d+$/)) {
        return false;
    }

    const hours: number = Number(hoursStr);
    const minutes: number = Number(minutesStr);

    const isValidHour = (hour: any) => Number.isInteger(hour) && hour >= 0
    const isValidMinutes = (minutes: any) => (Number.isInteger(minutes) && hours >= 0) || Number.isNaN(minutes);
    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
}