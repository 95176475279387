import React from "react";
import {HistoryLog, HistoryLogType} from "../../../redux/operation/types";
import moment from "moment";
import {getHHMMFromSeconds} from "../../../redux/operation/actions";

interface StateProps {
    historyLog: HistoryLog
}

const HistoryLogView = (props: StateProps) => {

    const getType = (type: HistoryLogType) => {
        switch (type) {
            case "create":
                return "Creazione"
            case "update":
                return "Modifica"
            default:
                return ""
        }
    }

    return (
        <div>
            <p className="small mb-0 pb-0"><b>{getType(props.historyLog.type)}</b> <span className="font-italic">{
                `${props.historyLog.user.name} ${props.historyLog.user.surname} - ${moment(props.historyLog.createdAt).format('DD-MM-YYYY HH:mm')}`
            }</span><br/>
            <b>Tempo svolto: {getHHMMFromSeconds(props.historyLog.logged_seconds)}</b>
            </p>
            <p className="small">{props.historyLog.notes}</p>
        </div>
    )
}

export default HistoryLogView