import React from "react";
import {User} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {HistoryLogFormData, OperationType} from "../../redux/operation/types";
import {useHistory} from "react-router-dom";
import Sidebar from "../components/layout/SideBar";
import Loader from "../components/loader/Loader";
import {fetchActivitiesSimple} from "../../redux/activity/actions";
import withAuth from "../utils/WithAuth";
import FormGeneralInformation from "./FormGeneralInformation";
import {createOperation} from "../../redux/operation/actions";
import FormEventConsultation from "./FormEventConsultation";
import FormPractice from "./FormPractice";
import FormTutoring from "./FormTutoring";
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";
import {ActivityListSimple} from "../../redux/activity/types";

interface OwnState {
    data: {
        isFetching: boolean
        isSubmitting: boolean
        activities: ActivityListSimple[],
        associations: {id: number, name: string}[],
        selectedAssociation?:number
        selectedActivity?: number
        type?: OperationType
    }
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>

const initialState: OwnState = {
    data: {
        isFetching: false,
        isSubmitting: false,
        activities: [],
        associations: [],
    }
}


const typeOfOperation: { [k: string]: string } = {
    "GENERAL_INFORMATION": 'Informazioni generali',
    "EVENT_CONSULTATION": 'Consulenza Evento',
    "PRACTICE": 'Pratiche',
    "TUTORING": 'Tutoring'
}

const CreateOperation = (props: PropsFromRedux) => {
    const history = useHistory();
    const initialTime = new URLSearchParams(history.location.search).get('time') !== null ? parseInt(new URLSearchParams(history.location.search).get('time')!, 10) : 0
    const [state, setState] = React.useState<OwnState>({
        data: {
            isFetching: false,
            isSubmitting: false,
            activities: [],
            associations:[],
        }
    })

    React.useEffect(() => {
        loadData();
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target
        setState({
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const loadData = () => {
        setState({
            data: {
                ...state.data,
                isFetching: true
            }
        });

        fetchActivitiesSimple()
        .then(response => {
            setState({
                data: {
                    ...state.data,
                    activities: response
                }
            })
        })
        .catch(e => {
            console.log(e)
        });
    }

    const onSubmit = (data: any, historyLog: HistoryLogFormData) => {
        setState({
            data: {
                ...state.data,
                isSubmitting: true
            }
        })
        if (state.data.type) {
            const [hours, minutes] = historyLog.logged_seconds.split(':')
            const logged_seconds = parseInt(hours, 10)*3600 + parseInt(minutes, 10) * 60
            createOperation(state.data.type, data, {
                ...historyLog,
                logged_seconds
            })
                .then(response => {
                    props.setToastMessage({
                        type: "success",
                        message: "Intervento creato con successo!"
                    })
                    history.push(`/operazioni/${response.id}/${state.data.type}`)
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }

    // Set association of the fetsched actvity
    state.data.activities.forEach(function(value){
        var temp_assoc = value.association.name;
        var find = false;
        state.data.associations.forEach(function(assoc){
            if(assoc.name === temp_assoc){
                find=true;
                return;
            }
        })
        if(find){
            return;
        }else{
            state.data.associations.push(value.association);
        }
    })
    // Only if is the first time that page loaded
    // Set parameter if come from Activity Page
    var query_string = window.location.search.substring(1);
    if(query_string != null){
        var paramsSplitted = query_string.split("=");
        if(paramsSplitted[0] == 'activity'){
            var selectedActivity : number = +paramsSplitted[1];
            console.log("Act: "+paramsSplitted[1] + " - lenght " + state.data.activities.length);
            for(let i = 0; i < state.data.activities.length; i++){
                var temp_id : number = state.data.activities[i].id;
                console.log("Compare : tmp_" + temp_id + " - params_" + selectedActivity);
                if(temp_id == selectedActivity){
                    console.log("Uguale");
                    state.data.selectedAssociation=state.data.activities[i].association.id;
                    break;
                }
            }
            state.data.selectedActivity=selectedActivity;
        }
    }
    
    return (
        <Sidebar activeMenu="OPERAZIONI">
            <h3 className="text-center">Crea Intervento</h3>
            {state.data.isFetching && (
                <Loader/>
            )}
            {!state.data.isFetching && state.data.activities.length === 0 && (
                <h6 className="text-center text-danger">
                    Non è stata creata alcuna attività. Per creare un'associazione è neccessaria un'attività.
                </h6>
            )}
            {!state.data.isFetching && state.data.activities.length > 0 && (
                <>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Seleziona l'associazione <abbr
                                className="text-danger">*</abbr></label>
                            <select
                                value={state.data.selectedAssociation || ''}
                                className="form-control"
                                name="selectedAssociation"
                                onChange={handleInputChange}
                                disabled={state.data.isSubmitting}
                            >
                                <option value="">-</option>
                                {state.data.associations.map(association => (
                                    <option value={association.id} key={`association_option_${association.id}`}>
                                        {association.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {state.data.selectedAssociation && (
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Seleziona l'attività <abbr
                                className="text-danger">*</abbr></label>
                            <select
                                value={state.data.selectedActivity || ''}
                                required={true}
                                className="form-control"
                                name="selectedActivity"
                                onChange={handleInputChange}
                                disabled={state.data.isSubmitting}
                            >
                                <option value="">-</option>
                                {state.data.activities.map(activity => (
                                    (activity.association.id == state.data.selectedAssociation) ?
                                    <option value={activity.id}
                                        // key={`activity_option_${activity.id}`}>{activity.progressive_association} {activity.association.name}
                                        key={`activity_option_${activity.id}`}>{activity.name} n: {activity.progressive} per {activity.association.name}
                                    </option>
                                    :null
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label className="font-weight-bold">Tipo di Intervento <abbr
                                className="text-danger">*</abbr></label>
                            <select
                                value={state.data.type || ''}
                                required={true}
                                className="form-control"
                                name="type"
                                onChange={handleInputChange}
                                disabled={state.data.isSubmitting}
                            >
                                <option value="">-</option>
                                {Object.keys(typeOfOperation).map(k => (
                                    <option value={k} key={`select_operation_${k}`}>{typeOfOperation[k]}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    )}
                    {state.data.type === "GENERAL_INFORMATION" && state.data.selectedActivity && (
                        <FormGeneralInformation
                            activity_id={state.data.selectedActivity}
                            isSubmitting={state.data.isSubmitting}
                            accumulated_seconds={initialTime}
                            onSubmit={onSubmit}
                        />
                    )}
                    {state.data.type === "EVENT_CONSULTATION" && state.data.selectedActivity && (
                        <FormEventConsultation
                            activity_id={state.data.selectedActivity}
                            isSubmitting={state.data.isSubmitting}
                            accumulated_seconds={initialTime}
                            onSubmit={onSubmit}
                        />
                    )}
                    {state.data.type === "PRACTICE" && state.data.selectedActivity && (
                        <FormPractice
                            activity_id={state.data.selectedActivity}
                            isSubmitting={state.data.isSubmitting}
                            onSubmit={onSubmit}
                            accumulated_seconds={initialTime}
                        />
                    )}
                    {state.data.type === "TUTORING" && state.data.selectedActivity && (
                        <FormTutoring
                            activity_id={state.data.selectedActivity}
                            isSubmitting={state.data.isSubmitting}
                            onSubmit={onSubmit}
                            accumulated_seconds={initialTime}
                        />
                    )}
                </>
            )}
        </Sidebar>
    )
}

export default withAuth(connector(CreateOperation))