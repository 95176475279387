import React from "react";
import {User, UserInfoUpdate} from "../../redux/user/types";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface StateProps {
    user: User
    isSubmitting: boolean
    onSubmit: (data: UserInfoUpdate) => void
}

const UpdateUserInfo = (props: StateProps) => {
    const [user, setUser] = React.useState<UserInfoUpdate>({
        name: props.user.name,
        surname: props.user.surname,
        email: props.user.email,
        roles: props.user.roles
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleChangeRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {value} = e.target
        setUser({
            ...user,
            roles: [value]
        })
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        props.onSubmit(user)
    }

    const resetData = () => {
        setUser({
            name: props.user.name,
            surname: props.user.surname,
            email: props.user.email,
            roles: props.user.roles
        })
    }

    const isUserDiff: boolean = user.name !== props.user.name
        || user.surname !== props.user.surname
        || user.roles !== props.user.roles
        || user.email !== props.user.email

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Nome <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={user.name}
                        name="name"
                        required={true}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Cognome <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={user.surname}
                        name="surname"
                        required={true}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Email <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={user.email}
                        name="email"
                        type="email"
                        required={true}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Ruoli <abbr className="text-danger">*</abbr></label>
                    <select
                        value={user.roles.length > 0 && user.roles[0] || ''}
                        onChange={handleChangeRole}
                        className="form-control"
                        required={true}
                    >
                        <option value={""}>-</option>
                        <option value={"admin"}>Admin</option>
                        <option value={"user"}>User</option>
                    </select>
                </div>
            </div>
            <div className="row flex-row justify-content-end">
                <button className="btn btn-danger" type="button" onClick={resetData} disabled={props.isSubmitting}>
                    Reset
                </button>
                <button className="btn btn-primary ml-2" type="submit" disabled={props.isSubmitting || !isUserDiff}>
                    {props.isSubmitting ? (
                        <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                    ) : "Conferma"}
                </button>
            </div>
        </form>
    )
}

export default UpdateUserInfo