import React from "react";
import {AssociationEnvironment} from "../../redux/association/types";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

interface StateProps {
    data: AssociationEnvironment[]
    deleteEnvironment: (data: AssociationEnvironment) => void
    updateEnvironment: (data: AssociationEnvironment) => void
}

const TableEnvironments = (props: StateProps) => {
    return (
        <table className="table table-striped table-bordered">
            <thead>
            <tr>
                <th>Id</th>
                <th>Nome</th>
                <th>Creato il</th>
                <th>Aggiornato il</th>
                <th>Azioni</th>
            </tr>
            </thead>
            <tbody>
            {props.data.length === 0 && (
                <tr>
                    <td colSpan={5}>
                        <p className="text-center font-weight-bold mb-0">Nessun ambito disponibile</p>
                    </td>
                </tr>
            )}
            {props.data.length > 0 && props.data.map(environment => (
                    <tr key={`environment_${environment.id}`}>
                        <th scope="col">{environment.id}</th>
                        <td>{environment.value}</td>
                        <td>{moment(environment.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                        <td>{moment(environment.updatedAt).format("DD-MM-YYYY hh:mm")}</td>
                        <td>
                            <FontAwesomeIcon
                                className="pointer-event"
                                icon={faEdit}
                                onClick={() => props.updateEnvironment(environment)}/>
                            {/*
                            <FontAwesomeIcon
                                className="ml-2"
                                icon={faTrash}
                                onClick={() => props.deleteEnvironment(environment)}
                            />
                            */}
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}

export default TableEnvironments