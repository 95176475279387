import React from "react";
import {Redirect} from "react-router-dom";

const withAuth = (Component: any) => {
    return () => {
        // Check if Authenticated
        const jwt = sessionStorage.getItem('BearerToken') // Handle return user context if authenticated or null if not


        // If Logged in, it will render the Component.
        if (jwt) {
            return <Component/>;
        } else {
            return <Redirect
                to={{
                    pathname: "/login",
                }}
            />
        }
    };
};


export default withAuth;
