import React from "react";
import {Modal} from "react-bootstrap";

interface StateProps {
    message: string
    confirmCallback: (confirm: boolean) => void
}

const ModalAskConfirm = (props: StateProps) => {
    return (
        <Modal show={true} onHide={() => props.confirmCallback(false)}>
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">Conferma</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text text-center">{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => props.confirmCallback(false)}
                >
                    Annulla
                </button>
                <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => props.confirmCallback(true)}
                >
                    Procedi
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAskConfirm