import React from "react";
import {OperationsResponse, OperationType} from "../../redux/operation/types";
import {User as UserType, UserCreation, UserInfoUpdate} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {
    fetchUsers,
    createUser as createUserAction,
    fetchCurrentUser,
    updateUser,
    setLogoutUser, updateUserPassword
} from "../../redux/user/actions";
import Sidebar from "../components/layout/SideBar";
import withAuth from "../utils/WithAuth";
import Loader from "../components/loader/Loader";
import {useHistory} from "react-router-dom";
import ModalCreateUser from "./ModalCreateUser";
import {EmitToast, generalServerError} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableUsers from "./TableUsers";
import UpdateUserInfo from "./UpdateUserInfo";
import UpdateUserPassword from "./UpdateUserPassword";

interface Props {
    user: UserType
}

const mapState = (state: AppState) => ({
    user: state.user.user
});

const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast),
        setLogoutUser
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>

const User = (props: PropsFromRedux) => {
    const history = useHistory()
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [createUserShow, setCreateUserShow] = React.useState<boolean>(false)
    const [users, setUsers] = React.useState<UserType[]>([])
    const [currentUser, setCurrentUser] = React.useState<UserType>()

    React.useEffect(() => {
        if (props.user && props.user.roles.includes('admin')) {
            loadData()
        } else {
            history.push('/')
        }
    }, [])

    const loadData = () => {
        if (!isFetching) {
            setIsFetching(true)
            Promise.all([
                fetchUsers(),
                fetchCurrentUser()
            ])
                .then(([users, user]) => {
                    setUsers(users)
                    setCurrentUser(user)
                })
                .catch(e => {
                    props.setToastMessage(generalServerError)
                })
                .finally(() => setIsFetching(false))
        }
    }

    const toggleModal = () => {
        setCreateUserShow(!createUserShow)
    }

    const createUser = (data: UserCreation) => {
        setIsSubmitting(true)
        createUserAction(data)
            .then(() => {
                props.setToastMessage({
                    message: 'Utente creato con successo!',
                    type: "success"
                })
                loadData()
            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                setIsSubmitting(false)
                setCreateUserShow(false)
            })
    }

    const updateUserCallback = (data: UserInfoUpdate) => {
        if (currentUser) {
            setIsSubmitting(true)
            updateUser(data, currentUser.id)
                .then(forceLogout)
                .catch(e => {
                    props.setToastMessage(generalServerError)
                })
                .finally(() => setIsSubmitting(false))
        }
    }

    const updateUserPasswordCallback = (newPassword: string, oldPassword: string) => {
        if (currentUser){
            setIsSubmitting(true)
            updateUserPassword(newPassword, oldPassword, currentUser.id)
                .then(forceLogout)
                .catch(e => {
                    props.setToastMessage({
                        type: "error",
                        message: e.message
                    })
                })
                .finally(() => {
                    setIsSubmitting(false)
                })
        }
    }

    const forceLogout = () => {
        props.setToastMessage({
            message: "Utente modificato con successo, dovrai rifare l'accesso!",
            type: "success"
        })
        props.setLogoutUser();
        sessionStorage.removeItem("BearerToken");
        history.push("/login")
    }

    return (
        <Sidebar activeMenu="UTENTI">
            {isFetching && (
                <Loader/>
            )}
            {!isFetching && (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row justify-content-between mb-3">
                                <h3>Modifica informazioni generali</h3>
                                <button className="btn btn-primary" onClick={toggleModal}>
                                    Crea Utente
                                </button>
                            </div>
                            {currentUser && (
                                <>
                                    <UpdateUserInfo
                                        user={currentUser}
                                        isSubmitting={isSubmitting}
                                        onSubmit={updateUserCallback}
                                    />
                                    <hr/>
                                    <UpdateUserPassword
                                        user={currentUser}
                                        isSubmitting={isSubmitting}
                                        onSubmit={updateUserPasswordCallback}
                                    />
                                </>
                            )}
                        </div>
                        <div className="col-md-6">
                            <TableUsers users={users}/>
                        </div>
                    </div>
                </>
            )}
            {createUserShow && (
                <ModalCreateUser
                    isSubmitting={isSubmitting}
                    closeModal={toggleModal}
                    onSubmit={createUser}
                />
            )}
        </Sidebar>
    )
}

export default withAuth(connector(User))