import React from "react";
import {User} from "../../redux/user/types";
import Sidebar from "../components/layout/SideBar";

import Loader from "../components/loader/Loader";
import {AssociationEnvironment} from "../../redux/association/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {useHistory} from "react-router-dom";
import withAuth from "../utils/WithAuth";
import {Modal} from "react-bootstrap";
import CreateEnvironment from "./CreateEnvironment";
import {
    createAssociationEnvironment, deleteEnvironment,
    fetchAssociationEnvironments,
    updateAssociationEnvironment
} from "../../redux/association/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import TableEnvironments from "./TableEnvironments";
import ModalAskConfirm from "../components/notifications/ModalAskConfirm";
import {setToastMessage} from "../../redux/layout/actions";
import {EmitToast} from "../../redux/layout/types";

interface OwnState {
    state: {
        data: AssociationEnvironment[]
        isFetching: boolean,
        showModalCreate: boolean,
        isSubmitting: boolean,
        updatedEnvironment?: AssociationEnvironment,
        modalToDelete?: AssociationEnvironment,
        updateData: boolean
    }
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>

const initialState: OwnState = {
    state: {
        data: [],
        isFetching: false,
        showModalCreate: false,
        isSubmitting: false,
        updateData: false
    }
}

const Environments = (props: PropsFromRedux) => {
    const history = useHistory()
    const [state, setState] = React.useState<OwnState>(initialState)
    React.useEffect(() => {
        loadData()
    }, [])

    React.useEffect(() => {
        if (state.state.updateData) {
            loadData()
        }
    }, [state.state.updateData])

    const loadData = () => {
        setState({
            state: {
                ...state.state,
                isFetching: true
            }
        })
        fetchAssociationEnvironments()
            .then(response => {
                setState({
                    state: {
                        ...state.state,
                        data: response,
                        updateData: false
                    }
                })
                console.log(response)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const createEnvironment = () => {
        setState({
            state: {
                ...state.state,
                showModalCreate: true
            }
        })
    }

    const closeCreateEnvironment = (update?: boolean) => {
        setState({
            state: {
                ...state.state,
                showModalCreate: false,
                updatedEnvironment: undefined
            }
        })
    }

    const onSubmit = async (data: string) => {
        setState({
            state: {
                ...state.state,
                isSubmitting: true
            }
        })
        try {
            if (state.state.updatedEnvironment) {
                await updateAssociationEnvironment(data, state.state.updatedEnvironment.id)
                props.setToastMessage({
                    type: 'success',
                    message: `L'ambito è stato modificato`
                })
            } else {
                await createAssociationEnvironment(data)
                props.setToastMessage({
                    type: 'success',
                    message: `L'ambito ${data} è stato creato`
                })
            }
        } catch (e) {
            console.log(e)
        } finally {
            setState({
                state: {
                    ...state.state,
                    isSubmitting: false,
                    showModalCreate: false,
                    updatedEnvironment: undefined,
                    updateData: true
                }
            })
        }
    }

    const updateEnvironment = (environment: AssociationEnvironment) => {
        setState({
            state: {
                ...state.state,
                updatedEnvironment: environment
            }
        })
    }

    const showModalDeleteEnvironment = (data: AssociationEnvironment) => {
        setState({
            state: {
                ...state.state,
                updatedEnvironment: undefined,
                modalToDelete: data
            }
        })
    }

    const deleteEnvironmentAction = async (confirm: boolean) => {
        if (confirm && state.state.modalToDelete) {
            try {
                await deleteEnvironment(state.state.modalToDelete.id)
                props.setToastMessage({
                    type: 'success',
                    message: `L'ambito: ${state.state.modalToDelete.value} è stato eliminato`
                })
                setState({
                    state: {
                        ...state.state,
                        modalToDelete: undefined,
                        updateData: true
                    }
                })
            } catch (e) {
                console.log(e)
            }
        } else {
            setState({
                state: {
                    ...state.state,
                    modalToDelete: undefined
                }
            })
        }
    }

    return (
        <Sidebar activeMenu="AMBITI">
            <div className="row mb-5">
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    <button
                        onClick={createEnvironment}
                        disabled={state.state.isFetching}
                        className="btn btn-primary float-right">
                        {
                            state.state.isFetching ? (
                                <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                            ) : "CREA AMBITO"
                        }
                    </button>
                </div>
            </div>
            {
                state.state.isFetching ? (
                    <Loader/>
                ) : (
                    <TableEnvironments
                        data={state.state.data}
                        updateEnvironment={updateEnvironment}
                        deleteEnvironment={showModalDeleteEnvironment}
                    />
                )
            }
            {state.state.updatedEnvironment && (
                <CreateEnvironment
                    visible={true}
                    closeModal={closeCreateEnvironment}
                    onSubmit={onSubmit}
                    isSubmitting={state.state.isSubmitting}
                    type="update"
                    value={state.state.updatedEnvironment.value}
                />
            )}
            {state.state.showModalCreate && (
                <CreateEnvironment
                    visible={true}
                    closeModal={closeCreateEnvironment}
                    onSubmit={onSubmit}
                    isSubmitting={state.state.isSubmitting}
                    type="new"
                />
            )}
            {state.state.modalToDelete && (
                <ModalAskConfirm
                    message={`Vuoi eliminare l'ambito: ${state.state.modalToDelete.value}?`}
                    confirmCallback={deleteEnvironmentAction}
                />
            )}
        </Sidebar>
    )
}

export default withAuth(connector(Environments))