import React from "react";
import {ReferentFormData} from "../../redux/association/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

interface Props {
    data: ReferentFormData
    key: number
    updateDate: (data: ReferentFormData) => void
    removeReferent: () => void
}

const FormReferent = (props: Props) => {

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        props.updateDate({
            ...props.data,
            [name]: value
        })
    }

    return (
        <>
            <div className="row">
                <div className="form-group col-md-3">
                    <label className="font-weight-bold">Nome <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={props.data.name}
                        onChange={handleInputChange}
                        name="name"
                        required={true}
                    />
                </div>
                <div className="form-group col-md-3">
                    <label className="font-weight-bold">Cognome <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={props.data.surname}
                        onChange={handleInputChange}
                        name="surname"
                        required={true}
                    />
                </div>
                <div className="form-group col-md-3">
                    <label className="font-weight-bold">E-mail <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={props.data.email}
                        onChange={handleInputChange}
                        name="email"
                        type="email"
                        required={true}
                    />
                </div>
                <div className="form-group col-md-3">
                    <label className="font-weight-bold">Cellulare</label>
                    <input
                        className="form-control"
                        value={props.data.phone || ''}
                        onChange={handleInputChange}
                        name="phone"
                    />
                </div>
                <div className="form-group col-md-3">
                    <label className="font-weight-bold">Ruolo</label>
                    <input
                        className="form-control"
                        value={props.data.role || ''}
                        onChange={handleInputChange}
                        name="role"
                    />
                </div>
            </div>
            <button className="btn btn-danger" type="button" onClick={props.removeReferent}>
                RIMUOVI
            </button>
        </>
    )
}

export default FormReferent