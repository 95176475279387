import {Association, AssociationEnvironment, AssociationFilters, AssociationFormData} from "./types";
import {fetchWrapper} from "../../network/fetchWrapper";
import FetchError from "../../network/fetchError";

export const fetchAssociations = (): Promise<Association[] | any> => {
    return fetchWrapper('/association', 'GET')
        .then(data => data.json)
        .catch(e => {
            throw e
        })
}

export const fetchAssocation = (id: number): Promise<Association | any> => {
    return fetchWrapper(`/association/${id}`, 'GET')
        .then(data => data.json)
        .catch(e => {
            throw e
        })
}

export const createAssociation = (data: AssociationFormData): Promise<Association | any> => {
    data = JSON.parse(JSON.stringify(data))
    Object.keys(data).forEach((key: string) => {
        // @ts-ignore
        if (!data[key]) data[key] = null
    })
    return fetchWrapper('/association', 'POST', {
        'Content-Type': 'application/json'
    }, data)
        .then((data) => data.json)
        .catch((e: FetchError) => {
            throw e
        })
}

export const createAssociationEnvironment = (value: string): Promise<AssociationEnvironment> => {
    return fetchWrapper('/association/environment', 'POST', {
        'Content-Type': 'application/json'
    }, {value})
        .then((data) => data.json as AssociationEnvironment)
        .catch((e: FetchError) => {
            throw e
        })
}

export const updateAssociationEnvironment = (value: string, id: number): Promise<AssociationEnvironment> => {
    return fetchWrapper(`/association/environment/${id}`, 'PUT', {
        'Content-Type': 'application/json'
    }, {value})
        .then((data) => data.json as AssociationEnvironment)
        .catch((e: FetchError) => {
            throw e
        })
}

export const fetchAssociationEnvironments = () : Promise<AssociationEnvironment[]> => {
    return fetchWrapper('/association/environment', 'GET')
        .then(data => data.json as AssociationEnvironment[])
        .catch(e => {
            throw e
        })
}

export const updateAssociation = (data: AssociationFormData, id: number): Promise<any> => {
    data = JSON.parse(JSON.stringify(data))
    Object.keys(data).forEach((key: string) => {
        // @ts-ignore
        if (!data[key]) data[key] = null
    })
    return fetchWrapper(`/association/${id}`, 'PUT', {
        'Content-Type': 'application/json'
    }, data)
        .catch(e => {
            throw e
        })
}

export const deleteAssociation = (id: number): Promise<any> => {
    return fetchWrapper(`/association/${id}`, 'DELETE')
        .catch(e => {
            throw e
        })
}

export const deleteEnvironment = (id: number): Promise<any> => {
    return fetchWrapper(`/association/environment/${id}`, 'DELETE')
        .catch(e => {
            throw e
        })
}

export const applyAssociationsFilters = (data: Association[], filters: AssociationFilters) => {
    return data.filter(association =>
        (!filters.denomination || association.name.toLowerCase().includes(filters.denomination.toLowerCase())) &&
        (!filters.environment || association.environment === filters.environment)
    )
}