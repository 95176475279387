import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

const Loader = () => {
    return (
        <div className="text-center">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} size={'3x'}/>
        </div>
    )
}

export default Loader