import React from "react";
import {HistoryLogCreation, HistoryLogFormData, HistoryLogType} from "../../../redux/operation/types";
import TimeInput from "../../utils/TimeInput";
import {getHHMMFromSeconds, isValidTimeHHMMInput} from "../../../redux/operation/actions";

interface StateProps {
    data: HistoryLogFormData
    updateData: (data: HistoryLogFormData) => void
}

const HistoryLogInput = (props: StateProps) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        props.updateData({
            ...props.data,
            [name]: value
        })
    }

    const updateAccumulatedSeconds = (logged_seconds: string) => {
        if (isValidTimeHHMMInput(logged_seconds)){
            props.updateData({
                ...props.data,
                logged_seconds
            })
        }
    }

    return (
        <div className="row">
            <div className="form-group col-md-4">
                <label className="font-weight-bold">Tempo impiegato <abbr className="text-danger">*</abbr></label>
                <TimeInput
                    value={props.data.logged_seconds}
                    updateValue={updateAccumulatedSeconds}
                />
            </div>
            <div className="form-group col-md-8">
                <label className="font-weight-bold">Cos'hai fatto? {props.data.type === 'update' && <abbr className="text-danger">*</abbr>}</label>
                <textarea
                    className="form-control"
                    value={props.data.notes}
                    onChange={handleInputChange}
                    name="notes"
                    required={props.data.type === 'update'}
                />
            </div>
        </div>
    )
}

export default HistoryLogInput