import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import {configureStore} from "./redux";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import './style/_variables.scss'


const store = configureStore();

const RNRedux = () => (

    <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
            <App/>
        </PersistGate>
    </Provider>
);

ReactDOM.render(<RNRedux />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
