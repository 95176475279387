import React from "react";
import {User} from "../../redux/user/types";
import Sidebar from "../components/layout/SideBar";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../redux";
import withAuth from "../utils/WithAuth";
import { useHistory } from "react-router-dom";
import {Association as AssociationType, AssociationEnvironment} from "../../redux/association/types";
import {fetchAssociationEnvironments, fetchAssociations} from "../../redux/association/actions";
import TableAssociations from "./TableAssociations";
import Loader from "../components/loader/Loader";

interface OwnState {
    state: {
        data: AssociationType[],
        environments: AssociationEnvironment[]
        isFetching: boolean,
    }
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>

const initialState: OwnState = {
    state: {
        data: [],
        isFetching: false,
        environments: []
    }
}

const Association = (props: PropsFromRedux) => {
    const history = useHistory()
    const [state, setState] = React.useState<OwnState>(initialState)
    React.useEffect(() => {
        setState({
            state: {
                ...state.state,
                isFetching: true
            }
        })
        Promise.all([
            fetchAssociations(),
            fetchAssociationEnvironments()
        ])
            .then(([associations, environments]) => {
                setState({
                    state: {
                        ...state.state,
                        data: associations,
                        environments
                    }
                })
            })
            .catch(e => {
                console.log(e)
            })
    }, [])

    const createAssociation = () => {
        history.push('/associazioni/new')
    }

    const goToAssociation = (id: number) => {
        history.push(`/associazioni/${id}`)
    }

    const goToUpdateAssociation = (id: number) => {
        history.push(`/associazioni/update/${id}`)
    }

    const updateEnvironment = () => {
        history.push(`/associazioni/`)
    }

    return (
        <Sidebar activeMenu={"ASSOCIAZIONI"}>
            {state.state.isFetching && (
                <Loader />
            )}
            {!state.state.isFetching && (
                <TableAssociations
                    associations={state.state.data}
                    viewAssociation={goToAssociation}
                    environments={state.state.environments}
                    createAssociationCb={createAssociation}
                    goToUpdateAssociation={goToUpdateAssociation}
                    // updateEnvironment={updateEnvironment}
                />
            )}
        </Sidebar>
    )
};

export default withAuth(connector(Association));