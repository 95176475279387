import React from "react";
import {Association, AssociationEnvironment, AssociationFilters} from "../../redux/association/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import {applyAssociationsFilters} from "../../redux/association/actions";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import CreateEnvironment from "./CreateEnvironment";
import {
    createAssociationEnvironment
} from "../../redux/association/actions";
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";

interface OwnState {
    state: {
        data: AssociationEnvironment[]
        isFetching: boolean,
        showModalCreate: boolean,
        isSubmitting: boolean,
        updatedEnvironment?: AssociationEnvironment,
        modalToDelete?: AssociationEnvironment,
        updateData: boolean
    }
}

const initialState: OwnState = {
    state: {
        data: [],
        isFetching: false,
        showModalCreate: false,
        isSubmitting: false,
        updateData: false
    }
}

interface StateProps {
    associations: Association[]
    environments: AssociationEnvironment[]
    viewAssociation: (id: number) => void
    goToUpdateAssociation: (id: number) => void
    createAssociationCb: () => void
}

const TableAssociations = (props: StateProps) => {
    const [filters, setFilters] = React.useState<AssociationFilters>({})
    const [state, setState] = React.useState<OwnState>(initialState)
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = event.target
        setFilters({
            ...filters,
            [name]: name === 'environment' ? parseInt(value, 10) : value
        })
    }
    const associations = applyAssociationsFilters(props.associations , filters)

    const createEnvironment = () => {
        setState({
            state: {
                ...state.state,
                showModalCreate: true
            }
        })
    }

    const closeCreateEnvironment = (update?: boolean) => {
        setState({
            state: {
                ...state.state,
                showModalCreate: false,
                updatedEnvironment: undefined
            }
        })
    }

    const onSubmitEnviroment = async (data: string) => {
        setState({
            state: {
                ...state.state,
                isSubmitting: true
            }
        })
        try {
            await createAssociationEnvironment(data)
            await setState({
                state: {
                    ...state.state,
                    showModalCreate: false,
                    updatedEnvironment: undefined
                }
            })
        } catch (e) {
            console.log(e)
        } finally {  
            alert('Ambito creato con successo');
            window.location.reload();
        }
    }

    return (
        <>
            <div className="row align-items-end mb-4">
                <div className="form-group col-md-4 mb-0">
                    <label className="font-weight-bold">
                        Denominazione
                    </label>
                    <input
                        className="form-control"
                        value={filters.denomination || ''}
                        name="denomination"
                        onChange={handleInputChange}
                        disabled={props.associations.length === 0}
                    />
                </div>
                <div className="form-group col-md-4 mb-0">
                    <label className="font-weight-bold">
                        Ambito
                    </label>
                    <select
                        className="form-control"
                        value={filters.environment || ''}
                        name="environment"
                        onChange={handleInputChange}
                        disabled={props.associations.length === 0 || props.environments.length === 0}
                    >
                        <option value="">-</option>
                        {props.environments.map(environment => (
                            <option value={environment.id} key={`option_environment_${environment.id}`}>
                                {environment.value}
                            </option>
                        ))}
                    </select>
                </div>
               

                <div className="col-md-2">
                    <button
                        onClick={createEnvironment}
                        disabled={state.state.isFetching}
                        className="btn btn-primary float-right">
                        {
                            state.state.isFetching ? (
                                <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                            ) : "CREA AMBITO"
                        }
                    </button>
                </div>
                {state.state.showModalCreate && (
                    <CreateEnvironment
                        visible={true}
                        closeModal={closeCreateEnvironment}
                        onSubmit={onSubmitEnviroment}
                        isSubmitting={state.state.isSubmitting}
                        type="new"
                    />
                )}
               
                <div className="col-md-2">
                    <button
                        onClick={props.createAssociationCb}
                        className="btn btn-primary float-right">
                        CREA ASSOCIAZIONE
                    </button>
                </div>
            </div>
            <div>
                <span className="font-weight-bold">
                    Totale associazioni create: {props.associations.length}
                </span>
                {props.associations.length !== associations.length && (
                    <span className="font-weight-bold ml-5">
                        Totale associazioni filtrate: {associations.length}
                    </span>
                )}
            </div>
            <table className="table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Denominazione</th>
                    <th>Ambito</th>
                    <th>Email</th>
                    <th>Azioni</th>
                </tr>
                </thead>
                <tbody>
                {associations.length === 0 && (
                    <tr>
                        <td colSpan={5}>
                            <p className="text-center font-weight-bold mb-0">Nessuna associazione disponibile</p>
                        </td>
                    </tr>
                )}
                {associations.length > 0 && associations.map(association => (
                    <tr key={`association_${association.id}`}>
                        <th scope="col">{association.id}</th>
                        <td>{association.name}</td>
                        <td>{association.association_environment.value}</td>
                        <td>{association.email}</td>
                        <td>
                            <FontAwesomeIcon icon={faEye} onClick={() => props.viewAssociation(association.id)}/>
                            <FontAwesomeIcon icon={faEdit} className="ml-3" onClick={() => props.goToUpdateAssociation(association.id)}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default TableAssociations