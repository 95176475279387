import React from "react";
import {User} from "../../redux/user/types";

interface StateProps {
    users: User[]
}

const TableUsers = (props: StateProps) => {
    return (
        <table className="table table-bordered table-hover table-striped">
            <thead>
            <tr>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Email</th>
                <th>Ruoli</th>
            </tr>
            </thead>
            <tbody>
            {props.users.map(user => (
                <tr key={`user_${user.id}`}>
                    <td>{user.name}</td>
                    <td>{user.surname}</td>
                    <td>{user.email}</td>
                    <td>{user.roles.join(', ')}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default TableUsers