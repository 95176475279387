import React from "react";
import {User} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {useHistory} from "react-router-dom";
import Sidebar from "../components/layout/SideBar";
import withAuth from "../utils/WithAuth";
import {ActivityListResponse} from "../../redux/activity/types";
import {fetchActivities} from "../../redux/activity/actions";
import Loader from "../components/loader/Loader";
import TableActivities from "./TableActivities";
import {Association} from "../../redux/association/types";
import {District} from "../../redux/district/types";
import {fetchAssociations} from "../../redux/association/actions";
import {fetchDistricts} from "../../redux/district/actions";

interface OwnState {
    data: {
        isFetching: boolean
        activities: ActivityListResponse[]
        associations: Association[]
        districts: District[]
    }
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>

const initialState: OwnState = {
    data: {
        isFetching: false,
        activities: [],
        districts: [],
        associations: []
    }
}

const Activity = (props: PropsFromRedux) => {
    const history = useHistory()
    const [state, setState] = React.useState<OwnState>(initialState)
    React.useEffect(() => {
        setState({
            data: {
                ...state.data,
                isFetching: true
            }
        })
        Promise.all([
            fetchActivities(),
            fetchAssociations(),
            fetchDistricts()
        ])
            .then(([activities, associations, districts]) => {
                setState({
                    data: {
                        ...state.data,
                        activities,
                        associations,
                        districts
                    }
                })
            })
            .catch(e => {
                console.log(e)
            })
    }, [])

    const createActivity = () => {
        history.push('/attivita/new')
    }

    const goToActivity = (id: number) => {
        history.push(`/attivita/${id}`)
    }

    return (
        <Sidebar activeMenu={"ATTIVITA"}>
            <div className="row mb-5">
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    <button
                        onClick={createActivity}
                        className="btn btn-primary float-right">
                        CREA ATTIVITÀ
                    </button>
                </div>
            </div>
            {state.data.isFetching && (
                <Loader/>
            )}
            {!state.data.isFetching && (
                <TableActivities
                    activities={state.data.activities}
                    associations={state.data.associations}
                    districts={state.data.districts}
                    goToActivity={goToActivity}
                />
            )}
        </Sidebar>
    )
}

export default withAuth(connector(Activity))