import React from "react";
import {EventConsultation} from "../../../redux/operation/types";
import {getHHMMFromSeconds, getStatusOfOperation} from "../../../redux/operation/actions";
import moment from "moment";
import ViewInterventionSelector from "./ViewInterventionSelectors";
import HistoryLogView from "../historyLog/HistoryLogView";
import {useHistory} from "react-router-dom";

interface Props {
    data: EventConsultation
}

const EventConsultationView = (props: Props) => {
    const history: any = useHistory()

    const update = () => {
        history.push(`/operazioni/update/${props.data.id}/EVENT_CONSULTATION`)
    }

    const downloadFile = () =>{
        let arraybuffer = props.data.pdf_document;
        let fileName = props.data.denomination;
        console.log(arraybuffer);

        // var binaryString = window.atob(base64);
        // var binaryLen = binaryString.length;
        // var bytes = new Uint8Array(binaryLen);
        // for (var i = 0; i < binaryLen; i++) {
        //    var ascii = binaryString.charCodeAt(i);
        //    bytes[i] = ascii;
        // }

        const arr = new Uint8Array(arraybuffer);
        console.log(arr);
        const blob = new Blob([arr], { type: 'application/pdf' });

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    const totalLoggedTime = props.data.history_logs_event_consultations.reduce((acc, curr) => acc+curr.logged_seconds,0)
    return (
        <>
            <div className="row">
                <div className="col-md-4"/>
                <div className="col-md-4">
                    <h3 className="text-center">CONSULTAZIONE EVENTO</h3>
                </div>
                <div className="col-md-4 row justify-content-end mr-0 pr-0">
                    <button className="btn btn-primary" onClick={update}>
                        MODIFICA
                    </button>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-md-6 border-right">
                    <p><b>Attività: </b>{props.data.activity.progressive_association} {props.data.activity.association.name}</p>
                    <p><b>Denominazione: </b>{props.data.denomination}</p>
                    <p><b>Descrizione: </b>{props.data.description || '-'}</p>
                    <p><b>Relazione: </b>{props.data.relation || '-'}</p>
                    <p><b>Data inizio evento: </b>{moment(props.data.start_date).format('DD-MM-YYYY')} - <b>Data fine evento: </b>{moment(props.data.end_date).format('DD-MM-YYYY')}</p>
                    {/* <p><b>Gradimento utente: </b>{props.data.user_quote}</p> */}
                    <p><b>Tempo accumulato: </b> {getHHMMFromSeconds(totalLoggedTime)}</p>
                    <hr/>
                    <p><b>Selettori Intervento</b></p>
                    <ViewInterventionSelector data={props.data.intervetion_selectors}/>
                </div>
                <div className="col-md-6">
                    <h5 className="text-right">Storico</h5>
                    {props.data.history_logs_event_consultations.map((historyLog, index) => (
                        <div key={`history_log_${historyLog.id}`}>
                            <HistoryLogView historyLog={historyLog} />
                            <hr/>
                        </div>
                    ))}
                </div>
            </div>
            {props.data.pdf_document && (
            <div className="row">
                <div className="col-md-3" />
                <div className="col-md-4 row justify-content-end mr-0 pr-0">
                    <button className="btn btn-primary" onClick={downloadFile}>
                        DOWNLOAD PDF
                    </button>
                </div>
            </div>
            )};
        </>
    )
}

export default EventConsultationView