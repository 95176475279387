import React from "react";
import {ActivityFilters, ActivityListResponse} from "../../redux/activity/types";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {getHHMMFromSeconds} from "../../redux/operation/actions";
import {District} from "../../redux/district/types";
import {Association} from "../../redux/association/types";
import {filterActivity} from "../../redux/activity/actions";

interface StateProps {
    activities: ActivityListResponse[]
    goToActivity: (id: number) => void
    districts: District[],
    associations: Association[]
}

const TableActivities = (props: StateProps) => {
    const [filters, setFilters] = React.useState<ActivityFilters>({})


    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target
        setFilters({
            ...filters,
            [name]: name === 'association' ? parseInt(value, 10) : value
        })
    }

    const activities = props.activities && filterActivity(filters, props.activities)
    const totalSpentTime = activities && activities.reduce((acc, curr) => acc + curr.logged_total_time, 0)

    return (
        <>
            <div className="row">
                <div className="form-group col-md-4">
                    <label className="font-weight-bold">Denominazione Attività</label>
                    <input
                        className="form-control"
                        value={filters.name || ''}
                        name="name"
                        onChange={handleFilterChange}
                        disabled={props.activities.length === 0}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label className="font-weight-bold">Data Primo Contatto</label>
                    <input
                        type="date"
                        value={filters.contact_date || ''}
                        name="contact_date"
                        onChange={handleFilterChange}
                        className="form-control"
                        disabled={props.activities.length === 0}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label className="font-weight-bold">Associazione</label>
                    <select
                        className="form-control"
                        value={filters.association || ''}
                        name="association"
                        onChange={handleFilterChange}
                    >
                        <option value="">-</option>
                        {props.associations.map(association => (
                            <option value={association.id}
                                    key={`association_${association.id}`}>({association.id}) {association.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label className="font-weight-bold">Comune</label>
                    <select
                        className="form-control"
                        value={filters.district || ''}
                        name="district"
                        onChange={handleFilterChange}
                    >
                        <option value="">-</option>
                        {props.districts.map(district => (
                            <option value={district.name} key={`district_${district.code}`}>{district.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {totalSpentTime !== undefined && (
                <span className="font-weight-bold">Totale tempo speso: {getHHMMFromSeconds(totalSpentTime)}</span>
            )}
            <table className="table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Denominazione</th>
                    <th>Progressivo</th>
                    <th>Operatore</th>
                    <th>Comune</th>
                    <th>Associazione</th>
                    {/* <th>Progressivo Attività</th> */}
                    <th>Data primo contatto</th>
                    {/* <th>Data creazione</th> */}
                    <th>Totale tempo speso</th>
                    <th>Azioni</th>
                </tr>
                </thead>
                <tbody>
                {(!activities || activities.length === 0) && (
                    <tr>
                        <td className="text-center font-weight-bold" colSpan={9}>
                            Nessun'attività disponibile
                        </td>
                    </tr>
                )}
                {activities && activities.length > 0 && activities.map(activity => (
                    <tr key={`acitivity_${activity.id}`}>
                        <th scope="col">{activity.id}</th>
                        <th>{activity.name}</th>
                        <th>{activity.progressive}</th>
                        <td>{activity.user.name} {activity.user.surname}</td>
                        <td>{activity.district}</td>
                        <td><b>({activity.association.id})</b> {activity.association.name}</td>
                        {/* <td>{activity.progressive_association}</td> */}
                        <td>{moment(activity.contact_date).format("DD-MM-YYYY")}</td>
                        {/* <td>{moment(activity.createdAt).format("DD-MM-YYYY HH:MM")}</td> */}
                        <td>{getHHMMFromSeconds(activity.logged_total_time)}</td>
                        <td>
                            <FontAwesomeIcon icon={faEye} onClick={() => props.goToActivity(activity.id)}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default TableActivities