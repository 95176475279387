import React from "react";
import { valueEventAriaMessage } from "react-select/src/accessibility";
import {IntervetionSelectors} from "../../redux/operation/types";

interface Props {
    intervetionSelectors: IntervetionSelectors,
    updateData: (intervetionSelectors: IntervetionSelectors) => void
    masterComponent: string
    isSubmitting: boolean
}


const InterventionSelectorsOptionInput = (props: Props) => {
    const updateData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target
        props.updateData({
            ...props.intervetionSelectors,
            // @ts-ignore
            [name]: !props.intervetionSelectors[name]
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_occupazioneSuoloPubblico`}
                               checked={props.intervetionSelectors.occupazioneSuoloPubblico}
                               disabled={props.isSubmitting}
                               name="occupazioneSuoloPubblico" onChange={updateData}/>
                        <label className="form-check-label"
                               htmlFor={`${props.masterComponent}_occupazioneSuoloPubblico`}>Occupazione suolo
                            pubblico</label>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_avvisoDiManifestazione`}
                               checked={props.intervetionSelectors.avvisoDiManifestazione}
                               disabled={props.isSubmitting}
                               name="avvisoDiManifestazione" onChange={updateData}/>
                        <label className="form-check-label"
                               htmlFor={`${props.masterComponent}_avvisoDiManifestazione`}>Avviso di manifestazione
                            (art. 18 TULPS)</label>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_somministrazioneAlimentiBevande`}
                               checked={props.intervetionSelectors.somministrazioneAlimentiBevande}
                               disabled={props.isSubmitting}
                               name="somministrazioneAlimentiBevande" onChange={updateData}/>
                        <label className="form-check-label"
                               htmlFor={`${props.masterComponent}_somministrazioneAlimentiBevande`}>Somministrazione
                            alimenti e bevande</label>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_comunicazioneAsl`}
                               checked={props.intervetionSelectors.comunicazioneAsl}
                               disabled={props.isSubmitting}
                               name="comunicazioneAsl" onChange={updateData}/>
                        <label className="form-check-label"
                               htmlFor={`${props.masterComponent}_comunicazioneAsl`}>Comunicazione ASL</label>
                    </div>
                </div>
            </div>
            <hr/>
            <p className="font-weight-bold">Agibilità art. 80 TULPS per pubblico spettacolo</p>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_art80Tulps200Persone`}
                               checked={props.intervetionSelectors.art80Tulps200Persone}
                               disabled={props.isSubmitting}
                               name="art80Tulps200Persone" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_art80Tulps200Persone`}>Minore
                            o uguale a 200 persone</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_art80TulpsMaggiore200PersoneCVLPS`}
                               checked={props.intervetionSelectors.art80TulpsMaggiore200PersoneCVLPS}
                               disabled={props.isSubmitting}
                               name="art80TulpsMaggiore200PersoneCVLPS" onChange={updateData}/>
                        <label className="form-check-label"
                               htmlFor={`${props.masterComponent}_art80TulpsMaggiore200PersoneCVLPS`}>Maggiore a 200
                        </label>
                    </div>
                </div>
                {/* <div className="col-md-4">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_art80TulpsMaggiore200Persone`}
                               checked={props.intervetionSelectors.art80TulpsMaggiore200Persone}
                               disabled={props.isSubmitting}
                               name="art80TulpsMaggiore200Persone" onChange={updateData}/>
                        <label className="form-check-label"
                               htmlFor={`${props.masterComponent}_art80TulpsMaggiore200Persone`}>Maggiore a 200 persone
                            senza convocazione CVLPS</label>
                    </div>
                </div> */}
            </div>
            <hr/>
            <p className="font-weight-bold">Autorizzazione pubblico spettacolo art.68 TULPS</p>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_art68Scia`}
                               checked={props.intervetionSelectors.art68Scia}
                               disabled={props.isSubmitting}
                               name="art68Scia" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_art68Scia`}>SCIA</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_art68Domanda`}
                               checked={props.intervetionSelectors.art68Domanda}
                               disabled={props.isSubmitting}
                               name="art68Domanda" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_art68Domanda`}>DOMANDA</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_derogaRumore`}
                               checked={props.intervetionSelectors.derogaRumore}
                               disabled={props.isSubmitting}
                               name="derogaRumore" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_derogaRumore`}>Deroga Rumore</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_fuochi`}
                               checked={props.intervetionSelectors.fuochi}
                               disabled={props.isSubmitting}
                               name="fuochi" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_fuochi`}>Fuochi</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_manifestioniSortaLocale`}
                               checked={props.intervetionSelectors.manifestioniSortaLocale}
                               disabled={props.isSubmitting}
                               name="manifestioniSortaLocale" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_manifestioniSortaLocale`}>Manifestazione di sorta locale</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_mostraMercato`}
                               checked={props.intervetionSelectors.mostraMercato}
                               disabled={props.isSubmitting}
                               name="mostraMercato" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_mostraMercato`}>Mostra mercato</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_spettacoliViaggianti`}
                               checked={props.intervetionSelectors.spettacoliViaggianti}
                               disabled={props.isSubmitting}
                               name="spettacoliViaggianti" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_spettacoliViaggianti`}>Spettacoli viaggianti</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_licenzaVenditaAlcolici`}
                               checked={props.intervetionSelectors.licenzaVenditaAlcolici}
                               disabled={props.isSubmitting}
                               name="licenzaVenditaAlcolici" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_licenzaVenditaAlcolici`}>Licenza fiscale vendita alcolici</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox"
                               id={`${props.masterComponent}_luminarie`}
                               checked={props.intervetionSelectors.luminarie}
                               disabled={props.isSubmitting}
                               name="luminarie" onChange={updateData}/>
                        <label className="form-check-label" htmlFor={`${props.masterComponent}_luminarie`}>Luminarie</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="form-group">
                <label>Altro</label>
                <textarea
                    className="form-control"
                    onChange={event => props.updateData({
                        ...props.intervetionSelectors,
                        altro: event.target.value
                    })}
                    value={props.intervetionSelectors.altro}
                />
            </div>
        </>
    )
}

export default InterventionSelectorsOptionInput