import {
    OperationUserStats,
    OperationUserStatsResponse,
    User,
    UserCreation,
    UserInfoUpdate,
    UserLoginForm,
    UserTimer
} from "./types";
import {fetchWrapper} from "../../network/fetchWrapper";
import moment from "moment";
import {getHHMMFromSeconds} from "../operation/actions";
import {
    EventConsultation,
    EventConsultationOp,
    GeneralInformation,
    GeneralInformationOp, HistoryLog,
    OperationsResponse, Practice, PracticeOp, Tutoring, TutoringOp
} from "../operation/types";

export function setLoggedUser(user: User) {
    return {
        type: 'SET_LOGGED_USER',
        payload: user
    }
}

export function setLogoutUser() {
    return {
        type: 'SET_LOGOUT_USER',
        payload: undefined
    }
}

export function setActiveTimer() {
    return {
        type: 'SET_ACTIVE_TIMER',
        payload: undefined
    }
}

export function setPauseTimer() {
    return {
        type: 'SET_PAUSE_TIMER',
        payload: undefined
    }
}

export function setTerminateTimer() {
    return {
        type: 'SET_TERMINATE_TIMER',
        payload: undefined
    }
}

export const loginUser = (userForm: UserLoginForm): Promise<User> => {
    return fetchWrapper('/user/login', 'POST', {
        'Content-Type': 'application/json'
    }, userForm)
        .then(data => data.json as User)
        .catch(e => {
            throw e
        })
}

export const fetchUsers = (): Promise<User[]> => {
    return fetchWrapper('/user', "GET")
        .then(data => data.json as User[])
        .catch(e => {
            throw e
        })
}

export const fetchCurrentUser = (): Promise<User> => {
    return fetchWrapper('/user/current-user', 'GET')
        .then(data => data.json as User)
        .catch(e => {
            throw e
        })
}

export const createUser = (data: UserCreation): Promise<any> => {
    return fetchWrapper('/user', 'POST', {
        'Content-Type': 'application/json'
    }, data)
        .catch(e => {
            throw e
        })
}

export const updateUser = (data: UserInfoUpdate, id: number): Promise<any> => {
    return fetchWrapper(`/user/${id}`, 'PUT', {
        'Content-Type': 'application/json'
    }, data)
        .catch(e => {
            throw e
        })
}

export const updateUserPassword = (newPassword: string, oldPassword: string, id: number): Promise<any> => {
    return fetchWrapper(`/user/${id}/password`, 'PUT', {
        'Content-Type': 'application/json'
    }, {
        newPassword,
        oldPassword
    })
        .catch(e => {
            throw e
        })
}

export const getTotalWorkingTime = (data: UserTimer): number => {
    const timer = JSON.parse(JSON.stringify(data))
    if (timer.active) {
        timer.workingPeriod.push(moment().unix())
    }
    let totalSeconds = 0
    for (let i = 1; i < timer.workingPeriod.length; i = i + 2) {
        totalSeconds += timer.workingPeriod[i] - timer.workingPeriod[i - 1]
    }
    return totalSeconds
}

export const fetchOperationByUser = (): Promise<OperationUserStatsResponse> => {
    return fetchWrapper('/user/stats', 'GET')
        .then((data: any) => ({
            generalInformations: (data.json.generalInformations as HistoryLog[]),
            eventConsultations: (data.json.eventConsultations as HistoryLog[]),
            practices: (data.json.practices as HistoryLog[]),
            tutorings: (data.json.tutorings as HistoryLog[])
        }))
        .catch(e => {
            throw e
        })
}

export const prepareDataForStatsOperationsUser = (data: OperationUserStatsResponse) => {
    const year = parseInt(moment().format('YYYY'), 10)
    const months: any = {
        Gennaio: getMonthDateRange(year, 1),
        Febbraio: getMonthDateRange(year, 2),
        Marzo: getMonthDateRange(year, 3),
        Aprile: getMonthDateRange(year, 4),
        Maggio: getMonthDateRange(year, 5),
        Giugno: getMonthDateRange(year, 6),
        Luglio: getMonthDateRange(year, 7),
        Agosto: getMonthDateRange(year, 8),
        Settembre: getMonthDateRange(year, 9),
        Ottobre: getMonthDateRange(year, 10),
        Novembre: getMonthDateRange(year, 11),
        Dicembre: getMonthDateRange(year, 12),
    }
    return Object.keys(months).reduce((acc: OperationUserStats[], key) => {
        const startDate = months[key].start
        const endDate = months[key].end
        const loggedTimeGeneralInformation = data.generalInformations.filter(gn => moment(gn.createdAt).isBetween(startDate, endDate))
            .reduce((totalTime, curr) => totalTime + curr.logged_seconds, 0)
        const loggedTimeEventConsultation = data.eventConsultations.filter(ec => moment(ec.createdAt).isBetween(startDate, endDate))
            .reduce((totalTime, curr) => totalTime + curr.logged_seconds, 0)
        const loggedTimeTutoring = data.tutorings.filter(tu => moment(tu.createdAt).isBetween(startDate, endDate))
            .reduce((totalTime, curr) => totalTime + curr.logged_seconds, 0)
        const loggedTimePractice = data.practices.filter(pr => moment(pr.createdAt).isBetween(startDate, endDate))
            .reduce((totalTime, curr) => totalTime + curr.logged_seconds, 0)
        acc.push({
            name: key,
            "Informazioni Generali": moment.duration(loggedTimeGeneralInformation * 1000).asHours(),
            "Consultazione Evento": moment.duration(loggedTimeEventConsultation * 1000).asHours(),
            Pratica: moment.duration(loggedTimePractice * 1000).asHours(),
            Tutoring: moment.duration(loggedTimeTutoring * 1000).asHours()
        })
        return acc
    }, [])
}

const getMonthDateRange = (year: number, month: number) => {
    var moment = require('moment');

    // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
    // array is 'year', 'month', 'day', etc
    var startDate = moment([year, month - 1]);

    // Clone the value before .endOf()
    var endDate = moment(startDate).endOf('month');

    // make sure to call toDate() for plain JavaScript date type
    return {start: startDate, end: endDate};
}