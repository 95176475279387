import React from "react";
import {User, UserLoginForm} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {loginUser, setLoggedUser, setLogoutUser} from "../../redux/user/actions";
import {useHistory} from "react-router-dom";
import './_index.scss'
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";

interface StateProps {
    data: UserLoginForm
}

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
    {
        setLoggedUser,
        setLogoutUser,
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);


type PropsFromRedux = ConnectedProps<typeof connector>

const Login = (props: PropsFromRedux) => {
    const history = useHistory()
    const [state, setState] = React.useState<StateProps>({
        data: {
            email: '',
            password: ''
        }})

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        loginUser(state.data)
            .then(data => {
                props.setLoggedUser(data)
                history.push('/');
                sessionStorage.setItem('BearerToken', data.jwt)
            })
            .catch(e => {
                props.setToastMessage({
                    message: 'Credenziali errate!',
                    type: 'error'
                })
                setState({
                    data: {
                        password: '',
                        email: ''
                    }
                })
                props.setLogoutUser()
                sessionStorage.removeItem('BearerToken')
            })
    }

    return (
        <div className="backgroundContainer">
            <div className="containerForm">
                <div>
                    <img src={process.env.PUBLIC_URL + '/unpliLogoBig.png'} alt={"Logo unpli"} width={200} height={50}/>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-3">
                        <label className="font-weight-bold">Email <abbr className="text-danger">*</abbr></label>
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            value={state.data.email}
                            onChange={handleInputChange}
                            required={true}
                        />
                    </div>
                    <div className="form-group">
                        <label className="font-weight-bold">Password <abbr className="text-danger">*</abbr></label>
                        <input
                            className="form-control"
                            type="password"
                            name="password"
                            value={state.data.password}
                            onChange={handleInputChange}
                            required={true}
                        />
                    </div>
                    <button className="btn btn-primary">LOGIN</button>
                </form>
            </div>
        </div>
    )
}

export default connector(Login)