import React from "react";
import {User} from "../../../redux/user/types";
import {SidebarState} from "../../../redux/layout/types";
import {setSidebarOpen} from "../../../redux/layout/actions";
import {
    getTotalWorkingTime,
    setActiveTimer,
    setLogoutUser,
    setPauseTimer, setTerminateTimer
} from "../../../redux/user/actions";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../../redux";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faPause, faPlay, faStop} from "@fortawesome/free-solid-svg-icons";
import {getHHMMFromSeconds} from "../../../redux/operation/actions";
import moment from "moment";
import ModalCloseTimer from "./ModalCloseTimer";
import {OperationType} from "../../../redux/operation/types";


interface Props {
    sidebar: SidebarState,
    user: User
}

const mapState = (state: AppState) => ({
    sidebar: state.sidebar,
    user: state.user.user,
    timer: state.user.timer
});

const connector = connect(
    mapState,
    {setSidebarOpen, setLogoutUser, setActiveTimer, setPauseTimer, setTerminateTimer}
);

type PropsFromRedux = ConnectedProps<typeof connector>

const NavBar = (props: PropsFromRedux) => {
    const [keyTimer, setKeyTimer] = React.useState<number>(0)
    const [showModalCloseTimer, setShowModalCloseTimer] = React.useState<boolean>(false)
    React.useEffect(() => {
        const interval = setInterval(() => {
            setKeyTimer(moment().unix())
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    const history = useHistory();
    const logout = () => {
        props.setLogoutUser();
        sessionStorage.removeItem("BearerToken");
        history.push("/login")
    };

    const closeTimer = (eliminate?: boolean) => {
        if (props.timer && eliminate) {
            props.setTerminateTimer()
        }
        setShowModalCloseTimer(false)
    }

    const showCloseTimer = () => {
        if (props.timer) {
            setShowModalCloseTimer(true)
        }
    }

    const callbackConfirmTimer = (id?: number, type?: OperationType) => {
        if (props.timer) {
            const totalTime = getTotalWorkingTime(props.timer)
            props.setTerminateTimer()
            if (id && type) {
                history.push(`/operazioni/update/${id}/${type}?time=${totalTime}`)
            } else {
                history.push(`/operazioni/new?time=${totalTime}`)
            }
        }
        setShowModalCloseTimer(false)
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light ">
            <div className="container-fluid">

                <button type="button" id="sidebarCollapse" className="btn btnPrimaryColor"
                        onClick={() => props.setSidebarOpen(!props.sidebar.isSidebarOpen)}>
                    <FontAwesomeIcon icon={faBars} color={'white'}/>
                </button>
                <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button"
                        data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <i className="fas fa-align-justify"/>
                </button>


                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav ml-auto">
                        {props.user &&
                        <li className="nav-item active">
                            <span
                                className="nav-link">Benvenuto {props.user.name + " " + props.user.surname}</span>
                        </li>}
                        {!props.timer && (
                            <li className="nav-item active">
                                <button className="btn btnSecondColor mr-5"
                                        onClick={() => props.setActiveTimer()}>AVVIA TIMER
                                </button>
                            </li>
                        )}
                        {props.timer && (
                            <>
                                <li className="nav-item active" key={`timer_${keyTimer}`}>
                                    <span
                                        className="nav-link">{getHHMMFromSeconds(getTotalWorkingTime(props.timer))}</span>
                                </li>
                                {props.timer.active && (
                                    <li className="nav-item active">
                                        <span className="nav-link"><FontAwesomeIcon icon={faPause}
                                                                                    onClick={props.setPauseTimer}/></span>
                                    </li>
                                )}
                                {!props.timer.active && (
                                    <li className="nav-item active">
                                        <span className="nav-link"><FontAwesomeIcon icon={faPlay}
                                                                                    onClick={props.setActiveTimer}/></span>
                                    </li>
                                )}
                                <li className="nav-item active">
                                    <span className="nav-link"><FontAwesomeIcon icon={faStop} onClick={showCloseTimer}/></span>
                                </li>
                            </>
                        )}
                        <li className="nav-item">
                            <button className="btn btnSecondColor"
                                    onClick={() => logout()}>LOGOUT
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            {showModalCloseTimer && (
                <ModalCloseTimer
                    close={closeTimer}
                    onSubmit={callbackConfirmTimer}
                />
            )}
        </nav>
    )
};
export default connector(NavBar);
