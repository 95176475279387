export interface SidebarState {
    isSidebarOpen: boolean,
    emitToast?: EmitToast
}

export interface EmitToast {
    /*
    *     toast.info('🦄 Wow so easy!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});
    * */
    message: string,
    type: 'success' | 'warning' | 'error'
}

export type ActiveRoute = 'HOME' | 'ASSOCIAZIONI' | 'ATTIVITA' | 'OPERAZIONI' | 'UTENTI' | 'AMBITI'

export const generalServerError: EmitToast = {
    type: "error",
    message: `Qualcosa è andato storto! Riprova più tardi o contatta l'assistenza.`
}