import React from "react";
import {GeneralInformationFormData, HistoryLogCreation, HistoryLogFormData} from "../../redux/operation/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {getHHMMFromSeconds, isValidTimeHHMMInput} from "../../redux/operation/actions";
import HistoryLogInput from "./historyLog/HistoryLogInput";

interface OwnState {
    data: GeneralInformationFormData,
}

interface StateProps {
    activity_id?: number
    isSubmitting: boolean
    onSubmit: (data: any, historyLog: HistoryLogFormData) => void
    accumulated_seconds: number,
    generalInformation?: GeneralInformationFormData
}

const FormGeneralInformation = (props: StateProps) => {
    const [state, setState] = React.useState<OwnState>({
        data: {
            ...(props.generalInformation ? {
                ...props.generalInformation
            } : {
                activity_id: props.activity_id!,
                relation: '',
                user_quote: '',
                pdf_document: new ArrayBuffer(1)
            })
        }
    })
    const [historyLogData, setHistoryLogData] = React.useState<HistoryLogFormData>({
        logged_seconds: props.accumulated_seconds ? getHHMMFromSeconds(props.accumulated_seconds) : '00:00',
        notes: '',
        type: props.generalInformation ? 'update' : 'create'
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target
        setState({
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        props.onSubmit(state.data, historyLogData)
    }

    const updatePDF = (event:any) => {
        let pdf_document = event.target.files[0];
        console.log(pdf_document);
        setState({
            data: {
                ...state.data,
                pdf_document
            }
        })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label className="font-weight-bold">Relazione <abbr className="text-danger">*</abbr></label>
                <textarea
                    value={state.data.relation}
                    onChange={handleInputChange}
                    name="relation"
                    required={true}
                    className="form-control"
                    disabled={props.isSubmitting}
                />
            </div>
            {/* <div className="form-group">
                <label className="font-weight-bold">Gradimento Utente <abbr className="text-danger">*</abbr></label>
                <textarea
                    value={state.data.user_quote}
                    onChange={handleInputChange}
                    name="user_quote"
                    required={true}
                    className="form-control"
                    disabled={props.isSubmitting}
                />
            </div> */}
            {/* <div className="row">
                <div className="col-md-5">
                    <p className="font-weight-bold">CARICA PDF PRATICA</p>
                    <input
                        type="file"
                        name="pdf_document"
                        accept=".pdf"
                        onChange={updatePDF}
                        className="btn btn-primary"/>
                    
                </div>
            </div> */}
            <hr/>
            <HistoryLogInput
                data={{
                    ...historyLogData
                }}
                updateData={setHistoryLogData}
            />
            <div className="float-right">
                <button className="btn btn-danger mr-5">
                    ANNULLA
                </button>
                <button type="submit" className="btn btn-success" disabled={props.isSubmitting}>
                    {
                        props.isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                        ) : "CONFERMA"
                    }
                </button>
            </div>
        </form>
    )
}

export default FormGeneralInformation