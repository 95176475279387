import React from "react";
import {User} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";
import {useHistory, useParams} from "react-router-dom";
import {
    EventConsultation,
    GeneralInformation, GeneralInformationFormData,
    HistoryLogFormData,
    OperationType,
    Practice,
    Tutoring
} from "../../redux/operation/types";
import {fetchOperation, updateOperation} from "../../redux/operation/actions";
import Sidebar from "../components/layout/SideBar";
import withAuth from "../utils/WithAuth";
import Loader from "../components/loader/Loader";
import FormGeneralInformation from "./FormGeneralInformation";
import FormEventConsultation from "./FormEventConsultation";
import FormTutoring from "./FormTutoring";
import FormPractice from "./FormPractice";

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});


const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>

const UpdateOperation = (props: PropsFromRedux) => {
    const history = useHistory()
    const initialTime = new URLSearchParams(history.location.search).get('time') !== null ? parseInt(new URLSearchParams(history.location.search).get('time')!, 10) : 0
    const params: any = useParams()
    const id: number = params.id
    const type: OperationType = params.type
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [data, setData] = React.useState<GeneralInformation | EventConsultation | Practice | Tutoring>()
    React.useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        if (!isFetching && id && type) {
            setIsFetching(true)
            try {
                const data = await fetchOperation(id, type)
                setData(data)
            } catch (e) {

            } finally {
                setIsFetching(false)
            }
        }
    }

    const generalInformation = type === 'GENERAL_INFORMATION' && data as GeneralInformation
    const eventConsultation = type === 'EVENT_CONSULTATION' && data as EventConsultation
    const tutoring = type === 'TUTORING' && data as Tutoring
    const practice = type === 'PRACTICE' && data as Practice

    const onSubmit = (data: any, historyLog: HistoryLogFormData) => {
        if (type && id) {
            setIsSubmitting(true)
            const [hours, minutes] = historyLog.logged_seconds.split(':')
            const logged_seconds = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60
            updateOperation(type, id, data, {
                ...historyLog,
                logged_seconds
            }).then(() => {
                props.setToastMessage({
                    type: "success",
                    message: "Intervento aggiornato con successo!"
                })
                history.push(`/operazioni/${id}/${type}`)
            })
                .catch(e => {
                    console.log(e)
                    setIsSubmitting(false)
                })
        }
    }

    return (
        <Sidebar activeMenu="OPERAZIONI">
            {isFetching && (
                <Loader/>
            )}
            {!isFetching && generalInformation && <FormGeneralInformation
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                accumulated_seconds={initialTime}
                generalInformation={{
                    user_quote: generalInformation.user_quote,
                    relation: generalInformation.relation,
                    activity_id: generalInformation.activity_id,
                    pdf_document: generalInformation.pdf_document
                }}
            />
            }
            {!isFetching && eventConsultation && <FormEventConsultation
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                accumulated_seconds={initialTime}
                eventConsultation={{
                    intervetion_selectors: eventConsultation.intervetion_selectors,
                    user_quote: eventConsultation.user_quote,
                    relation: eventConsultation.relation,
                    description: eventConsultation.description,
                    end_date: eventConsultation.end_date,
                    start_date: eventConsultation.start_date,
                    denomination: eventConsultation.denomination,
                    activity_id: eventConsultation.activity_id,
                    pdf_document: eventConsultation.pdf_document
                }}
            />
            }
            {!isFetching && tutoring && <FormTutoring
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                accumulated_seconds={initialTime}
                tutoring={{
                    intervetion_selectors: tutoring.intervetion_selectors,
                    user_quote: tutoring.user_quote,
                    relation: tutoring.relation,
                    description: tutoring.description,
                    end_date: tutoring.end_date,
                    start_date: tutoring.start_date,
                    denomination: tutoring.denomination,
                    activity_id: tutoring.activity_id,
                    pdf_document: tutoring.pdf_document
                }}
            />
            }
            {!isFetching && practice && <FormPractice
                isSubmitting={isSubmitting}
                accumulated_seconds={initialTime}
                onSubmit={onSubmit}
                practice={{
                    intervetion_selectors: practice.intervetion_selectors,
                    user_quote: practice.user_quote,
                    relation: practice.relation,
                    description: practice.description,
                    end_date: practice.end_date,
                    start_date: practice.start_date,
                    denomination: practice.denomination,
                    activity_id: practice.activity_id,
                    pdf_document: practice.pdf_document
                }}
            />
            }
        </Sidebar>
    )
}

export default withAuth(connector(UpdateOperation))