import React from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    TooltipPayload,
    BarChart,
    Legend,
    Bar,
    ResponsiveContainer,
    Label,
} from 'recharts';
import {OperationUserStats} from "../../redux/user/types";
import {getHHMMFromSeconds} from "../../redux/operation/actions";

interface Props {
    data: OperationUserStats[]
}

const UserStats = (props: Props) => {

    const customTooltip = (value: number, name: string, entry: TooltipPayload) => {
        return getHHMMFromSeconds(value*3600)
    };


    return (
        <>
            <h3 className="text-center">Tempo totale impiegato</h3>
            <ResponsiveContainer width={'100%'} height={400}>
                <BarChart
                    width={1200}
                    height={300}
                    data={props.data}
                    margin={{
                        top: 50, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis>
                        <Label position={'top'} offset={30}>
                            Ore
                        </Label>
                    </YAxis>

                    <Tooltip formatter={(value, name, entry) => customTooltip(value as number, name, entry)}/>
                    <Legend/>
                    <Bar dataKey="Informazioni Generali" fill="#C62828"/>
                    <Bar dataKey="Consultazione Evento" fill="#303F9F"/>
                    <Bar dataKey="Tutoring" fill="#C0CA33"/>
                    <Bar dataKey="Pratica" fill="#6D4C41"/>
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}

export default UserStats