import {User, UserTimer} from "./types";
import moment from "moment";

interface UserState {
    user?: User,
    timer?: UserTimer
}

const initialState: UserState = {}

export function userReducer(
    state = initialState,
    action: any
): UserState {
    switch (action.type) {
        case 'SET_LOGGED_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'SET_LOGOUT_USER':
            return {
                ...state,
                user: undefined
            }
        case 'SET_ACTIVE_TIMER':
            return {
                ...state,
                timer: {
                    active: true,
                    workingPeriod: state.timer ? [...state.timer.workingPeriod, moment().unix()] : [moment().unix()]
                }
            }
        case 'SET_PAUSE_TIMER':
            if (state.timer){
                return {
                    ...state,
                    timer: {
                        active: false,
                        workingPeriod: [...state.timer.workingPeriod, moment().unix()]
                    }
                }
            }
            return state
        case 'SET_TERMINATE_TIMER': {
            return {
                ...state,
                timer: undefined
            }
        }
        default:
            return state
    }

}