import { HexBase64BinaryEncoding } from "crypto";
import {User, UserSample} from "../user/types";

export interface GeneralInformation {
    id: number
    activity_id: number
    relation: string
    user_quote: string
    closed: boolean
    createdAt: string
    updatedAt: string
    user: User
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    },
    pdf_document: ArrayBuffer,
    history_logs_general_informations: HistoryLog[],
}

export interface GeneralInformationSimple {
    id: number
    activity_id: number
    relation: string
    user_quote: string
    closed: boolean
    createdAt: string
    updatedAt: string
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    },
}

export interface EventConsultation {
    id: number
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    createdAt: string
    updatedAt: string
    closed: boolean
    user: User
    pdf_document: ArrayBuffer
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    }
    history_logs_event_consultations: HistoryLog[]
}

export interface EventConsultationsSimple {
    id: number
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    createdAt: string
    updatedAt: string
    closed: boolean
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    }
}

export interface Practice {
    id: number
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    createdAt: string
    updatedAt: string
    closed: boolean
    user: User
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    },
    pdf_document: ArrayBuffer
    history_logs_practices: HistoryLog[],
}

export interface PracticeSimple {
    id: number
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    createdAt: string
    updatedAt: string
    closed: boolean
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    }
}

export interface Tutoring {
    id: number
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    createdAt: string
    updatedAt: string
    closed: boolean
    user: User
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    }
    history_logs_tutorings: HistoryLog[]
    pdf_document: ArrayBuffer
}

export interface TutoringSimple {
    id: number
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    createdAt: string
    updatedAt: string
    closed: boolean
    activity: {
        id: number
        progressive_association: number
        association: {
            name: string
            id: number
        }
    }
}

export interface GeneralInformationFormData {
    activity_id: number
    relation: string
    user_quote: string
    pdf_document: ArrayBuffer
}

export interface OperationsResponse {
    generalInformations: GeneralInformationOp[]
    eventConsultations: EventConsultationOp[]
    practices: PracticeOp[]
    tutorings: TutoringOp[]
}

export interface OperationsSimpleResponse {
    generalInformations: GeneralInformationSimple[]
    eventConsultations: EventConsultationsSimple[]
    practices: PracticeSimple[]
    tutorings: TutoringSimple[]
}

export interface EventConsultationFormData {
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    pdf_document: ArrayBuffer
}

export interface PracticeFormData {
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    pdf_document: ArrayBuffer
}

export interface TutoringFormData {
    activity_id: number
    denomination: string
    description: string
    relation: string
    start_date: string
    end_date: string
    intervetion_selectors: IntervetionSelectors
    user_quote: string
    pdf_document: ArrayBuffer
}

export interface IntervetionSelectors {
    occupazioneSuoloPubblico: boolean
    avvisoDiManifestazione: boolean
    somministrazioneAlimentiBevande: boolean
    comunicazioneAsl: boolean
    art80Tulps200Persone: boolean
    art80TulpsMaggiore200Persone: boolean
    art80TulpsMaggiore200PersoneCVLPS: boolean
    art68Scia: boolean
    art68Domanda: boolean
    derogaRumore: boolean
    fuochi: boolean
    manifestioniSortaLocale: boolean
    mostraMercato: boolean
    spettacoliViaggianti: boolean
    licenzaVenditaAlcolici: boolean
    luminarie: boolean
    altro: string
}

export const initialStateIntervetionSelectors: IntervetionSelectors = {
    occupazioneSuoloPubblico: false,
    avvisoDiManifestazione: false,
    somministrazioneAlimentiBevande: false,
    comunicazioneAsl: false,
    art80Tulps200Persone: false,
    art80TulpsMaggiore200Persone: false,
    art80TulpsMaggiore200PersoneCVLPS: false,
    art68Scia: false,
    art68Domanda: false,
    derogaRumore: false,
    fuochi: false,
    manifestioniSortaLocale: false,
    mostraMercato: false,
    spettacoliViaggianti: false,
    licenzaVenditaAlcolici: false,
    luminarie: false,
    altro: ""
}

export interface HistoryLogFormData {
    logged_seconds: string
    notes: string
    type: HistoryLogType
}

export interface HistoryLogCreation {
    logged_seconds: number
    notes: string
    type: HistoryLogType
}

export interface HistoryLog {
    createdAt: string
    id: number
    logged_seconds: number
    notes: string
    type: HistoryLogType
    updatedAt: string
    user: UserSample
}

export type HistoryLogType = 'create' | 'update'

export type OperationType = 'GENERAL_INFORMATION' | 'PRACTICE' | 'TUTORING' | 'EVENT_CONSULTATION'

export class GeneralInformationOp implements GeneralInformation{
    accumulated_seconds: number;
    activity: { id: number; progressive_association: number; association: { name: string; id: number } };
    activity_id: number;
    closed: boolean;
    createdAt: string;
    history_logs_general_informations: HistoryLog[];
    id: number;
    relation: string;
    updatedAt: string;
    user: User;
    user_quote: string;
    pdf_document: ArrayBuffer;
    constructor(generalInformation: GeneralInformation) {
        this.id = generalInformation.id
        this.activity = generalInformation.activity
        this.activity_id = generalInformation.activity_id
        this.closed = generalInformation.closed
        this.createdAt = generalInformation.createdAt
        this.history_logs_general_informations = generalInformation.history_logs_general_informations
        this.accumulated_seconds = this.history_logs_general_informations.reduce((acc, curr) => acc+curr.logged_seconds,0)
        this.relation = generalInformation.relation
        this.updatedAt = generalInformation.updatedAt
        this.user = generalInformation.user
        this.user_quote = generalInformation.user_quote
        this.pdf_document = generalInformation.pdf_document
    }
}

export class EventConsultationOp implements EventConsultation{
    activity: { id: number; progressive_association: number; association: { name: string; id: number } };
    activity_id: number;
    closed: boolean;
    createdAt: string;
    denomination: string;
    description: string;
    end_date: string;
    history_logs_event_consultations: HistoryLog[];
    id: number;
    intervetion_selectors: IntervetionSelectors;
    pdf_document: ArrayBuffer;
    relation: string;
    start_date: string;
    updatedAt: string;
    user: User;
    user_quote: string;
    accumulated_seconds: number;
    constructor(eventConsultation: EventConsultation) {
        this.id = eventConsultation.id
        this.activity = eventConsultation.activity
        this.activity_id = eventConsultation.activity_id
        this.closed = eventConsultation.closed
        this.createdAt = eventConsultation.createdAt
        this.history_logs_event_consultations = eventConsultation.history_logs_event_consultations
        this.accumulated_seconds = this.history_logs_event_consultations.reduce((acc, curr) => acc+curr.logged_seconds,0)
        this.relation = eventConsultation.relation
        this.updatedAt = eventConsultation.updatedAt
        this.user = eventConsultation.user
        this.user_quote = eventConsultation.user_quote
        this.denomination = eventConsultation.denomination
        this.description = eventConsultation.description
        this.end_date = eventConsultation.end_date
        this.intervetion_selectors = eventConsultation.intervetion_selectors
        this.pdf_document = eventConsultation.pdf_document
        this.start_date = eventConsultation.start_date
    }
}

export class TutoringOp implements Tutoring{
    activity: { id: number; progressive_association: number; association: { name: string; id: number } };
    activity_id: number;
    closed: boolean;
    createdAt: string;
    denomination: string;
    description: string;
    end_date: string;
    history_logs_tutorings: HistoryLog[];
    id: number;
    intervetion_selectors: IntervetionSelectors;
    pdf_document: ArrayBuffer;
    relation: string;
    start_date: string;
    updatedAt: string;
    user: User;
    user_quote: string;
    accumulated_seconds: number;
    constructor(tutoring: Tutoring) {
        this.id = tutoring.id
        this.activity = tutoring.activity
        this.activity_id = tutoring.activity_id
        this.closed = tutoring.closed
        this.createdAt = tutoring.createdAt
        this.history_logs_tutorings = tutoring.history_logs_tutorings
        this.accumulated_seconds = this.history_logs_tutorings.reduce((acc, curr) => acc+curr.logged_seconds,0)
        this.relation = tutoring.relation
        this.updatedAt = tutoring.updatedAt
        this.user = tutoring.user
        this.user_quote = tutoring.user_quote
        this.denomination = tutoring.denomination
        this.description = tutoring.description
        this.end_date = tutoring.end_date
        this.intervetion_selectors = tutoring.intervetion_selectors
        this.start_date = tutoring.start_date
        this.pdf_document =  tutoring.pdf_document
    }
}

export class PracticeOp implements Practice{
    activity: { id: number; progressive_association: number; association: { name: string; id: number } };
    activity_id: number;
    closed: boolean;
    createdAt: string;
    denomination: string;
    description: string;
    end_date: string;
    history_logs_practices: HistoryLog[];
    id: number;
    intervetion_selectors: IntervetionSelectors;
    pdf_document: ArrayBuffer;
    relation: string;
    start_date: string;
    updatedAt: string;
    user: User;
    user_quote: string;
    accumulated_seconds: number;
    constructor(practice: Practice) {
        this.id = practice.id
        this.activity = practice.activity
        this.activity_id = practice.activity_id
        this.closed = practice.closed
        this.createdAt = practice.createdAt
        this.history_logs_practices = practice.history_logs_practices
        this.accumulated_seconds = this.history_logs_practices.reduce((acc, curr) => acc+curr.logged_seconds,0)
        this.relation = practice.relation
        this.updatedAt = practice.updatedAt
        this.user = practice.user
        this.user_quote = practice.user_quote
        this.denomination = practice.denomination
        this.description = practice.description
        this.end_date = practice.end_date
        this.intervetion_selectors = practice.intervetion_selectors
        this.start_date = practice.start_date
        this.pdf_document = practice.pdf_document
    }
}