import React from "react";
import {
    EventConsultationFormData, HistoryLogFormData,
    initialStateIntervetionSelectors,
    IntervetionSelectors
} from "../../redux/operation/types";
import moment from "moment";
import InterventionSelectorsOptionInput from "./InterventionSelectorsOptionInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {getHHMMFromSeconds} from "../../redux/operation/actions";
import HistoryLogInput from "./historyLog/HistoryLogInput";
import SimpleReactFileUpload from "./FileUploader";
import PDFFileUploader from "./FileUploader";

interface OwnState {
    data: EventConsultationFormData
}

interface StateProps {
    activity_id?: number
    isSubmitting: boolean
    onSubmit: (data: any, historyLog: HistoryLogFormData) => void
    accumulated_seconds: number,
    eventConsultation?: EventConsultationFormData
}
const file = new File(["foo"], "foo.txt", {
    type: "text/plain",
  });
const initialState: OwnState = {
    data: {
        activity_id: 0,
        denomination: '',
        description: '',
        end_date: moment().format('YYYY-MM-DD'),
        intervetion_selectors: initialStateIntervetionSelectors,
        relation: '',
        start_date: moment().format('YYYY-MM-DD'),
        user_quote: '',
        pdf_document: new ArrayBuffer(1),
    }
}

const FormEventConsultation = (props: StateProps) => {
    const [state, setState] = React.useState<OwnState>({
        data: {
            ...(props.eventConsultation ?
                    props.eventConsultation : {
                        ...initialState.data,
                        activity_id: props.activity_id!
                    }
            )
        }
    })
    const [historyLog, setHistoryLog] = React.useState<HistoryLogFormData>({
        logged_seconds: props.accumulated_seconds ? getHHMMFromSeconds(props.accumulated_seconds) : '00:00',
        notes: '',
        type: props.eventConsultation ? 'update' : 'create'
    })


    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        props.onSubmit(state.data, historyLog)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target
        setState({
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const updateInterventionSelectors = (intervetion_selectors: IntervetionSelectors) => {
        setState({
            data: {
                ...state.data,
                intervetion_selectors
            }
        })
    }

    const updatePDF = (event:any) => {
        let pdf_document = event.target.files[0];
        console.log(pdf_document);
        setState({
            data: {
                ...state.data,
                pdf_document
            }
        })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label className="font-weight-bold">Denominazione evento <abbr className="text-danger">*</abbr></label>
                <input
                    className="form-control"
                    value={state.data.denomination}
                    onChange={handleInputChange}
                    name="denomination"
                    required={true}
                    disabled={props.isSubmitting}
                />
            </div>
            <hr/>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Data inizio evento <abbr
                        className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={state.data.start_date}
                        type="date"
                        onChange={handleInputChange}
                        name="start_date"
                        required={true}
                        //max={state.data.end_date}
                        disabled={props.isSubmitting}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Data fine evento <abbr className="text-danger">*</abbr></label>
                    <input
                        className="form-control"
                        value={state.data.end_date}
                        type="date"
                        //min={state.data.end_date}
                        onChange={handleInputChange}
                        name="end_date"
                        required={true}
                        disabled={props.isSubmitting}
                    />
                </div>
            </div>
            <hr/>
            <div className="form-group">
                <label className="font-weight-bold">Descrizione evento</label>
                <textarea
                    className="form-control"
                    value={state.data.description}
                    onChange={handleInputChange}
                    name="description"
                    disabled={props.isSubmitting}
                />
            </div>
            <hr/>
            <div className="form-group">
                <label className="font-weight-bold">Relazione</label>
                <textarea
                    className="form-control"
                    value={state.data.relation}
                    onChange={handleInputChange}
                    name="relation"
                    disabled={props.isSubmitting}
                />
            </div>
            <hr/>
            {/* <div className="form-group">
                <label className="font-weight-bold">Gradimento utente</label>
                <textarea
                    className="form-control"
                    value={state.data.user_quote}
                    onChange={handleInputChange}
                    name="user_quote"
                    disabled={props.isSubmitting}
                />
            </div> */}
            <hr/>
            {/* <PDFFileUploader
                intervetionSelectors={state.data.intervetion_selectors}
                updateData={updateInterventionSelectors}
                isSubmitting={props.isSubmitting}
                masterComponent="FormPractice"/> */}
            <hr/>
            <InterventionSelectorsOptionInput
                intervetionSelectors={state.data.intervetion_selectors}
                updateData={updateInterventionSelectors}
                isSubmitting={props.isSubmitting}
                masterComponent="FormEventConsultation"
            />
            <hr/>
            <HistoryLogInput
                data={historyLog}
                updateData={setHistoryLog}
            />
            <div className="row">
                <div className="col-md-5">
                    <p className="font-weight-bold">CARICA PDF PRATICA</p>
                    <input
                        type="file"
                        name="pdf_document"
                        accept=".pdf"
                        onChange={updatePDF}
                        className="btn btn-primary"/>
                    
                </div>
            </div>
            <hr/>
            <div className="float-right">
                <button className="btn btn-danger mr-5">
                    ANNULLA
                </button>
                <button type="submit" className="btn btn-success" disabled={props.isSubmitting}>
                    {
                        props.isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                        ) : "CONFERMA"
                    }
                </button>
            </div>
        </form>
    )
}

export default FormEventConsultation