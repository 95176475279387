import {EmitToast} from "./types";

export function setSidebarOpen(isOpen: boolean) {
    return {
        type: 'SET_SIDEBAR_OPEN',
        payload: isOpen
    }
}

export function setToastMessage(emitToast?: EmitToast) {
    return {
        type: 'SET_TOAST_MESSAGE',
        payload: emitToast
    }
}