import {createStore, combineReducers, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import {userReducer} from "./user/reducers";
import {sidebarReducer} from "./layout/reducers";

const rootReducer = combineReducers({
    user: userReducer,
    sidebar: sidebarReducer
})

export type AppState = ReturnType<typeof rootReducer>;
const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export  function configureStore() {
    const middlewares = [thunkMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    const store = createStore(
        persistedReducer,
        composeWithDevTools(middleWareEnhancer)
    );
    // @ts-ignore
    let persistor = persistStore(store);
    return { store, persistor }
}
