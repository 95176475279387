import React from "react";
import {IntervetionSelectors} from "../../../redux/operation/types";

interface Props {
    data: IntervetionSelectors
}

const ViewInterventionSelector = (props: Props) => {
    return (
        <ul>
            {props.data.occupazioneSuoloPubblico && <li>Occupazione suolo pubblico</li>}
            {props.data.avvisoDiManifestazione && <li>Avviso di manifestazione (art. 18 TULPS)</li>}
            {props.data.somministrazioneAlimentiBevande && <li>Somministrazione alimenti e bevande</li>}
            {props.data.comunicazioneAsl && <li>Comunicazione ASL</li>}
            {props.data.art80Tulps200Persone && <li>Agibilità art. 80 TULPS per pubblico spettacolo minore o uguale a 200 persone</li>}
            {props.data.art80TulpsMaggiore200PersoneCVLPS && <li>Agibilità art. 80 TULPS per pubblico spettacolo maggiore o uguale a 200 persone con convocazione CVLPS</li>}
            {props.data.art80TulpsMaggiore200Persone && <li>Agibilità art. 80 TULPS per pubblico spettacolo maggiore o uguale a 200 persone senza convocazione CVLPS</li>}
            {props.data.art68Scia && <li>Autorizzazione pubblico spettacolo art. 68 TULPS SCIA</li>}
            {props.data.art68Domanda && <li>Autorizzazione pubblico spettacolo art. 68 TULPS DOMANDA</li>}
            {props.data.derogaRumore && <li>Deroga Rumore</li>}
            {props.data.fuochi && <li>Fuochi</li>}
            {props.data.manifestioniSortaLocale && <li>Manifestazione di sorta locale</li>}
            {props.data.mostraMercato && <li>Mostra mercato</li>}
            {props.data.spettacoliViaggianti && <li>Spettacoli viaggianti</li>}
            {props.data.licenzaVenditaAlcolici && <li>Licenza fiscale vendita alcolici</li>}
            {props.data.luminarie && <li>Luminarie</li>}
            {props.data.altro && <p><span><b>Altro: </b></span> {props.data.altro}</p>}
        </ul>
    )
}

export default ViewInterventionSelector