import React from "react";
import {AssociationEnvironment, AssociationFormData, ReferentFormData} from "../../redux/association/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faPlus} from "@fortawesome/free-solid-svg-icons";
import FormReferent from "./FormReferent";
import { useHistory } from "react-router-dom";

interface OwnState {
    data: AssociationFormData
}

interface Props {
    onSubmit: (data: AssociationFormData) => any
    isSubmitting: boolean,
    environments: AssociationEnvironment[]
    data: AssociationFormData
}



const FormAssociation = (props: Props) => {
    const history = useHistory()
    const [state, setState] = React.useState<OwnState>({
        data: props.data
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const addReference = () => {
        const {association_referents} = state.data
        association_referents.push({
            name: '',
            phone: '',
            email: '',
            surname: '',
            role: ''
        })
        setState({
            ...state,
            data: {
                ...state.data,
                association_referents
            }
        })
    }

    const updateReferent = (index: number, referent: ReferentFormData) => {
        const {association_referents} = state.data
        association_referents[index] = referent
        setState({
            ...state,
            data: {
                ...state.data,
                association_referents
            }
        })
    }

    const removeReferent = (index: number) => {
        const {association_referents} = state.data
        setState({
            ...state,
            data: {
                ...state.data,
                association_referents: association_referents.filter((referent, pos) => pos !== index)
            }
        })
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        props.onSubmit(state.data)
    }

    const goToAssociations = () => {
        history.push('/associazioni')
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-4 form-group">
                    <label className="font-weight-bold">Denominazione <abbr className="text-danger">*</abbr></label>
                    <input
                        value={state.data.name}
                        name="name"
                        required={true}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-4 form-group">
                    <label className="font-weight-bold">Ambito <abbr className="text-danger">*</abbr></label>
                    <select
                        className="form-control"
                        value={state.data.environment}
                        name="environment"
                        onChange={handleInputChange}
                        required={true}
                    >
                        {props.environments.map(environment => (
                            <option key={`environment_${environment.id}`} value={environment.id}>{environment.value}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label className="font-weight-bold">E-mail <abbr className="text-danger">*</abbr></label>
                    <input
                        type="email"
                        name="email"
                        value={state.data.email}
                        onChange={handleInputChange}
                        className="form-control"
                        required={true}
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">C.F.</label>
                    <input
                        maxLength={16}
                        type="text"
                        name="fiscal_code"
                        value={state.data.fiscal_code || ''}
                        onChange={handleInputChange}
                        className="form-control"
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">P. IVA</label>
                    <input
                        maxLength={16}
                        name="vat"
                        value={state.data.vat || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Telefono</label>
                    <input
                        name="telephone"
                        value={state.data.telephone || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Cellulare</label>
                    <input
                        name="phone"
                        value={state.data.phone || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Indirizzo</label>
                    <input
                        name="address"
                        value={state.data.address || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Comune</label>
                    <input
                        name="municipality"
                        value={state.data.municipality || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">CAP</label>
                    <input
                        name="cap"
                        maxLength={5}
                        value={state.data.cap || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Provincia</label>
                    <input
                        name="province"
                        value={state.data.province || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">SITO</label>
                    <input
                        name="site"
                        value={state.data.site || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Facebook</label>
                    <input
                        name="facebook"
                        value={state.data.facebook || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Instagram</label>
                    <input
                        name="instagram"
                        value={state.data.instagram || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Presidente</label>
                    <input
                        name="president"
                        value={state.data.president || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Segretario</label>
                    <input
                        name="segretary"
                        value={state.data.segretary || ''}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <hr/>
            {state.data.association_referents.length > 0 ? (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Referenti</h3>
                        </div>
                        <div className="col-md-6">
                            <button type="button" className="btn btn-primary float-right" onClick={addReference} disabled={props.isSubmitting}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        </div>
                    </div>
                    <hr/>
                </>
            ) : (
                <div>
                    <button className="btn btn-primary" onClick={addReference} disabled={props.isSubmitting}>
                        AGGIUNGI REFERENTE
                    </button>
                </div>
            )}
            {state.data.association_referents.map((referent, index) => (
                <div key={`index_referent_${index}`}>
                    <FormReferent
                        data={referent}
                        updateDate={data => updateReferent(index, data)}
                        key={index}
                        removeReferent={() => removeReferent(index)}
                    />
                    <hr/>
                </div>
            ))}
            <hr/>
            <div className="float-right">
                <button onClick={goToAssociations} className="btn btn-danger mr-5">
                    ANNULLA
                </button>
                <button type="submit" className="btn btn-success" disabled={props.isSubmitting}>
                    {
                        props.isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                        ) : "CONFERMA"
                    }
                </button>
            </div>
        </form>
    )
}

export default FormAssociation