import {
    ActivityInterface,
    ActivityFormData,
    ActivityFilters,
    ActivityClass, ActivityListResponse, ActivityListSimple
} from "./types";
import {fetchWrapper} from "../../network/fetchWrapper";

export const createActivity = (data: ActivityFormData): Promise<any> => {
    return fetchWrapper('/activity', 'POST', {
        'Content-Type': 'application/json'
    }, data)
        .then(response => response.json)
        .catch(e => {
            throw e
        })
}

export const fetchActivities = (): Promise<ActivityListResponse[]> => {
    return fetchWrapper('/activity', 'GET')
        .then(data => data.json as ActivityListResponse[])
        .catch(e => {
            throw e
        })
}

export const fetchActivitiesSimple = (): Promise<ActivityListSimple[]> => {
    return fetchWrapper('/activity/simple', 'GET')
        .then(data => data.json as ActivityListSimple[])
        .catch(e => {
            throw e
        })
}

export const fetchActivity = (id: number): Promise<ActivityClass> => {
    return fetchWrapper(`/activity/${id}`, 'GET')
        .then(data => new ActivityClass(data.json as ActivityInterface))
        .catch(e => {
            throw e
        })
}

export const filterActivity = (filters: ActivityFilters, data: ActivityListResponse[]) => {
    return data.filter(activity =>
        (!filters.name || activity.name.toLowerCase().includes(filters.name.toLowerCase())) &&
        (!filters.contact_date || activity.contact_date.toLowerCase().includes(filters.contact_date.toLowerCase())) &&
        (!filters.district || activity.district === filters.district) &&
        (!filters.association || activity.association.id === filters.association)
    )
}