import React from "react";
import {User} from "../../redux/user/types";
import {AppState} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import Sidebar from "../components/layout/SideBar";
import {useParams, useHistory} from "react-router-dom";
import {fetchActivity} from "../../redux/activity/actions";
import withAuth from "../utils/WithAuth";
import {ActivityClass} from "../../redux/activity/types";
import Loader from "../components/loader/Loader";
import {act} from "react-dom/test-utils";
import moment from "moment";
import {getHHMMFromSeconds} from "../../redux/operation/actions";
import {Accordion, Button, Card} from "react-bootstrap";
import TableGeneralInformation from "../operations/tables/TableGeneraInformation";
import {OperationType} from "../../redux/operation/types";
import TableEventConsultation from "../operations/tables/TableEventConsultation";
import TablePractice from "../operations/tables/TablePractice";
import TableTutoring from "../operations/tables/TableTutoring";

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState
);

type PropsFromRedux = ConnectedProps<typeof connector>

const ViewActivity = (props: PropsFromRedux) => {
    const history = useHistory()
    const [activity, setActivity] = React.useState<ActivityClass>()
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const params: any = useParams()
    React.useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        if (!isFetching) {
            setIsFetching(true)
            fetchActivity(params.id)
                .then(data =>
                    setActivity(data)
                )
                .catch(e => {
                    console.log(e)
                })
        }
    }

    const goToDetail = (id: number, type: OperationType) =>{
        history.push(`/operazioni/${id}/${type}`)
    }

    const createOperation = (activity: number) => (event:any) =>{
        history.push(`/operazioni/new?activity=${activity}`)
    }

    return (
        <Sidebar activeMenu="ATTIVITA">
            {!activity && <Loader/>}
            {activity && (
                <>
                    <h3>Attività: <i>{activity.name}</i> nr: {activity.progressive} per
                        l'associazione: <i>{activity.association.name}</i></h3>
                    <p><b>Numero totale di
                        interventi: </b>{activity.tutorings.length + activity.general_informations.length
                    + activity.practices.length + activity.event_consultations.length}</p>
                    <p><b>Comune: </b>{activity.district}</p>
                    <p><b>Data primo contatto: </b>{moment(activity.contact_date).format('DD-MM-YYYY')}</p>
                    <p><b>Tipologia primo contatto: </b>{activity.contact_tipology || '-'}</p>
                    {/* <p><b>Prevalenza del
                        collegamento: </b>{activity.link_prevalence.length > 0 && `${activity.link_prevalence.join(', ')}.` || '-'}
                    </p> */}
                    <p>Creata il: <b>{moment(activity.createdAt).format('DD-MM-YYYY HH:MM')}</b> da parte
                        di <b>{activity.user.name} {activity.user.surname}</b></p>
                    <p><b>Totale tempo impiegato:</b> {getHHMMFromSeconds(activity.totalTime)}</p>
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Informazioni
                                    generali: {getHHMMFromSeconds(activity.totalTimeGeneralInformations)} ({activity.general_informations.length})
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <TableGeneralInformation
                                        generalInformations={activity.general_informations}
                                        goToGeneralInformation={(id: number) => goToDetail(id, "GENERAL_INFORMATION")}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    Consulenza
                                    Evento: {getHHMMFromSeconds(activity.totalTimeEventConsultations)} ({activity.event_consultations.length})
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <TableEventConsultation
                                        eventConsultations={activity.event_consultations}
                                        goToEventConsultation={(id: number) => goToDetail(id, "EVENT_CONSULTATION")}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    Pratiche: {getHHMMFromSeconds(activity.totalTimePractices)} ({activity.practices.length})
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <TablePractice
                                        practices={activity.practices}
                                        goToPractice={(id: number) => goToDetail(id, "PRACTICE")}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    Tutoring: {getHHMMFromSeconds(activity.totalTimeTutorings)} ({activity.tutorings.length})
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <TableTutoring
                                        tutorings={activity.tutorings}
                                        goToTutoring={(id: number) => goToDetail(id, "TUTORING")}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <br/>
                        <div className="row mb-5">
                            <div className="col-md-9">

                            </div>
                            <div className="col-md-3">
                                <button
                                    onClick={createOperation(activity.id)}
                                    className="btn btn-primary float-right">
                                    CREA INTERVENTO
                                </button>
                            </div>
                        </div>
                    </Accordion>
                </>
            )}
        </Sidebar>
    )
}

export default withAuth(connector(ViewActivity))