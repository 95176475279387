import React from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

interface StateProps {
    visible: boolean,
    closeModal: (update?: boolean) => void
    onSubmit: (data: string) => void
    isSubmitting: boolean,
    type: 'new' | 'update'
    value?: string
}

const CreateEnvironment = (props: StateProps) => {
    const [value, setValue] = React.useState<string>(props.value || '')

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        props.onSubmit(value)
    }

    return (
        <Modal show={props.visible} onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{props.type === 'new' ? 'Crea' : 'Modifica'} Ambito</Modal.Title>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="form-group">
                        <label className="font-weight-bold">Nome <abbr className="text-danger">*</abbr></label>
                        <input
                            className="form-control"
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            required={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" type="button" onClick={event => props.closeModal()}>
                        Annulla
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={props.isSubmitting}>
                        {props.isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                        ) : "Conferma"}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateEnvironment