import React from "react";
import { CSSProperties } from 'react';
import {ActiveRoute, SidebarState} from "../../../redux/layout/types";
import {Link} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../../redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from "./NavBar";
import './index.scss'
import {setToastMessage} from "../../../redux/layout/actions";


interface Props {
    sidebar: SidebarState
}

const mapState = (state: AppState) => ({
    sidebar: state.sidebar,
    user: state.user.user
});

const connector = connect(
    mapState,
    {
        setToastMessage: () => setToastMessage(undefined)
    }
);

interface OwnState {
    children: React.ReactNode,
    activeMenu?: ActiveRoute
}

type PropsFromRedux = ConnectedProps<typeof connector> & OwnState

const Sidebar = (props: PropsFromRedux) => {
    return (
        <div className="wrapper">
            <nav id="sidebar" className={`${props.sidebar.isSidebarOpen ? "" : "active"}`}>
                <div className="sidebar-header text-center">
                    <img src={process.env.PUBLIC_URL + '/unpliLogoSmall.png'} alt={"logo unpli"}/>
                </div>

                <ul className="list-unstyled components">
                    <p>MENU</p>
                    <li className={`${props.activeMenu === 'HOME' ? 'active' : ''}`}>
                        <Link to={"/"}>Home</Link>
                    </li>
                    <li className={`${props.activeMenu === 'ASSOCIAZIONI' ? 'active' : ''}`}>
                        <Link to={"/associazioni"}>Associazioni</Link>
                    </li>
                    {(props.activeMenu === 'ASSOCIAZIONI' || props.activeMenu === 'AMBITI')&& (
                        <li className={`${props.activeMenu === 'AMBITI' ? 'active' : ''}`} >
                            <Link className="submenu" to={"/ambiti"}>Ambiti</Link>
                        </li>
                    )}
                    <li className={`${props.activeMenu === 'ATTIVITA' ? 'active' : ''}`}>
                        <Link to={"/attivita"}>Attività</Link>
                    </li>
                    <li className={`${props.activeMenu === 'OPERAZIONI' ? 'active' : ''}`}>
                        <Link to={"/operazioni"}>Intervento</Link>
                    </li>
                    {props.user && props.user.roles && props.user.roles.includes('admin') && (
                        <li className={`${props.activeMenu === 'UTENTI' ? 'active' : ''}`}>
                            <Link to={"/utenti"}>Utenti</Link>
                        </li>
                    )}
                </ul>

            </nav>

            <div id="content">
                <NavBar/>
                {props.children}
            </div>
        </div>
    )
};
export default connector(Sidebar);