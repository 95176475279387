import React from "react";
import {User} from "../../redux/user/types";
import Sidebar from "../components/layout/SideBar";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../redux";
import withAuth from "../utils/WithAuth";
import FormAssociation from "./FormAssociation";
import "./_index.scss"
import {Association, AssociationEnvironment, AssociationFormData} from "../../redux/association/types";
import {
    createAssociation,
    fetchAssocation,
    fetchAssociationEnvironments,
    updateAssociation
} from "../../redux/association/actions";
import {useHistory, useParams} from "react-router-dom";
import Loader from "../components/loader/Loader";
import {EmitToast} from "../../redux/layout/types";
import {setToastMessage} from "../../redux/layout/actions";

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});



const connector = connect(
    mapState,
    {
        setToastMessage: (emitToast?: EmitToast) => setToastMessage(emitToast)
    }
);

type PropsFromRedux = ConnectedProps<typeof connector>


const UpdateAssociation = (props: PropsFromRedux) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [environments, setEnvironments] = React.useState<AssociationEnvironment[]>([])
    const [association, setAssociation] = React.useState<Association>()
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const params: any = useParams()
    const history: any = useHistory()
    React.useEffect(() => {
        setIsFetching(true)
        Promise.all([
            fetchAssocation(params.id),
            fetchAssociationEnvironments()
        ])
            .then(([association, environments]) => {
                setAssociation(association)
                setEnvironments(environments)
            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                setIsFetching(false)
            })

    }, [])

    const onSubmit = (data: AssociationFormData) => {
        setIsSubmitting(true)
        if (association){
            updateAssociation(data, association.id)
                .then(response => {
                    props.setToastMessage({
                        message: `Associazione aggiornata!`,
                        type: 'success'
                    })
                    setIsSubmitting(false)
                    history.push(`/associazioni/${association.id}`)
                })
                .catch(e => console.log(e))
        }
    }

    return (
        <Sidebar activeMenu={"ASSOCIAZIONI"}>
            <h3 className="text-center mb-4">Modifica ASSOCIAZIONE</h3>
            {isFetching && (
                <Loader />
            )}
            {association && !isFetching && environments.length > 0 && (
                <FormAssociation
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    environments={environments}
                    data={{
                        environment: association.environment,
                        address: association.address,
                        municipality: association.municipality,
                        cap: association.cap,
                        province: association.province,
                        association_referents: association.association_referents,
                        segretary: association.segretary,
                        president: association.president,
                        site: association.site,
                        facebook: association.facebook,
                        instagram: association.instagram,
                        phone: association.phone,
                        telephone: association.telephone,
                        vat: association.vat,
                        fiscal_code: association.fiscal_code,
                        email: association.email,
                        name: association.name
                    }}
                />
            )}
        </Sidebar>
    )
};

export default withAuth(connector(UpdateAssociation));