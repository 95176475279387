import React from "react";
import {Tutoring, TutoringOp} from "../../../redux/operation/types";
import moment from "moment";
import {getHHMMFromSeconds, getStatusOfOperation} from "../../../redux/operation/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEye} from "@fortawesome/free-solid-svg-icons";

interface StateProps {
    tutorings: TutoringOp[]
    goToTutoring: (id: number) => void
}

const TableTutoring = (props: StateProps) => {
    return (
        <table className="table table-bordered table-hover table-striped">
            <thead>
            <tr>
                <th>Id</th>
                <th>Attività</th>
                <th>Utente</th>
                <th>Denominazione</th>
                <th>Data creazione</th>
                <th>Tempo accumulato</th>
                <th>Stato</th>
                <th>Azioni</th>
            </tr>
            </thead>
            <tbody>
            {props.tutorings.length === 0 && (
                <tr>
                    <td colSpan={7} className="font-weight-bold text-center">
                        Nessun intervento disponibile
                    </td>
                </tr>
            )}
            {props.tutorings.length > 0 && props.tutorings.map(tutoring => (
                <tr key={`tutoring_${tutoring.id}`}>
                    <th scope="col">{tutoring.id}</th>
                    <td>{tutoring.activity.progressive_association} {tutoring.activity.association.name}</td>
                    <td>{tutoring.user.name} {tutoring.user.surname}</td>
                    <td>{tutoring.denomination}</td>
                    <td>{moment(tutoring.createdAt).format('DD-MM-YYYY HH:MM')}</td>
                    <td>{getHHMMFromSeconds(tutoring.accumulated_seconds)}</td>
                    <td>{getStatusOfOperation(tutoring.closed)}</td>
                    <td>
                        <FontAwesomeIcon icon={faEye} onClick={() => props.goToTutoring(tutoring.id)}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default TableTutoring