import React from "react";
import {OperationUserStatsResponse, User} from "../../redux/user/types";
import Sidebar from "../components/layout/SideBar";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../redux";
import withAuth from "../utils/WithAuth";
import UserStats from "../user/UserStats";
import {fetchOperationByUser, prepareDataForStatsOperationsUser} from "../../redux/user/actions";
import Loader from "../components/loader/Loader";

interface Props {
    user: User
}

const mapState = (state: AppState) => ({
    user: state.user
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>


const Home = (props: PropsFromRedux) => {
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [data, setData] = React.useState<OperationUserStatsResponse>()
    React.useEffect(() => {
        loadData()
    }, [])
    const loadData = () => {
        if (!isFetching){
            setIsFetching(true)
            fetchOperationByUser()
                .then(response => setData(response))
                .catch(e => {

                })
                .finally(() => {
                    setIsFetching(false)
                })
        }
    }
    return (
        <Sidebar activeMenu={"HOME"}>
            {isFetching && (
                <Loader />
            )}
            {!isFetching && data && (
                <UserStats data={prepareDataForStatsOperationsUser(data)}/>
            )}
        </Sidebar>
    )
};

export default withAuth(connector(Home));