import React from "react";
import {ActivityFormData} from "../../redux/activity/types";
import {District} from "../../redux/district/types";
import {Association} from "../../redux/association/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

interface OwnState {
    data: ActivityFormData
}

interface StateProps {
    districts: District[]
    associations: Association[]
    isSubmitting: boolean
    onSubmit: (data: ActivityFormData) => void
}

const initialState: OwnState = {
    data: {
        contact_date: '',
        contact_tipology: '',
        district: '',
        link_prevalence: [],
        reference_suap: '',
        association_id: undefined,
        progressive: 1,
        name: ''
    }
}

const contactTipologies: string[] = [
    "Telefono",
    "Social Media",
    "Mail",
    "Front Office",
    "Forum Sito"
]

const linkPrevalences: string[] = [
    "Telefono",
    "Social Media",
    "Mail",
    "Front Office",
]

const FormActivity = (props: StateProps) => {
    const [state, setState] = React.useState<OwnState>(initialState)

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const {name, value} = event.target
        setState({
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        props.onSubmit(state.data)
    }

    const changeDataPrevalenceLink = (event: React.ChangeEvent<HTMLInputElement>) => {
        let link_prevalence = state.data.link_prevalence
        if (!link_prevalence) {
            link_prevalence = []
        }
        if (link_prevalence.length > 1) {
            link_prevalence.shift()
        }
        link_prevalence.push(event.target.value)
        setState({
            data: {
                ...state.data,
                link_prevalence
            }
        })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Seleziona il comune <abbr
                        className="text-danger">*</abbr></label>
                    <select
                        value={state.data.district}
                        onChange={handleInputChange}
                        required={true}
                        className="form-control"
                        name="district"
                    >
                        <option value=''>-</option>
                        {props.districts.map(district => (
                            <option value={district.name} key={`district_${district.code}`}>{district.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Seleziona l'associazione <abbr className="text-danger">*</abbr></label>
                    <select
                        value={state.data.association_id}
                        onChange={handleInputChange}
                        required={true}
                        className="form-control"
                        name="association_id"
                    >
                        <option value=''>-</option>
                        {props.associations.map(association => (
                            <option value={association.id}
                                    key={`association_${association.id}`}>{association.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Attività <abbr className="text-danger">*</abbr></label>
                    <input
                        value={state.data.name}
                        name="name"
                        onChange={handleInputChange}
                        className="form-control"
                        required={true}
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Data primo contatto <abbr
                        className="text-danger">*</abbr></label>
                    <input
                        type="date"
                        value={state.data.contact_date}
                        name="contact_date"
                        onChange={handleInputChange}
                        className="form-control"
                        max={moment().format('YYYY-MM-DD')}
                        required={true}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label className="font-weight-bold">Suap di riferimento</label>
                    <input
                        value={state.data.reference_suap}
                        name="reference_suap"
                        onChange={handleInputChange}
                        className="form-control"
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-6 row flex-column align-items-center justify-content-center">
                    <label className="font-weight-bold">Tipologia primo contatto</label>
                    <div>
                        {contactTipologies.map((contactTipology) => (
                            <div className="form-check form-check-inline" key={`radio_${contactTipology}`}>
                                <input className="form-check-input" type="radio"
                                       id={`radio_${contactTipology}`}
                                       checked={state.data.contact_tipology === contactTipology}
                                       name="contact_tipology" value={contactTipology} onChange={handleInputChange}/>
                                <label className="form-check-label"
                                       htmlFor={`radio_${contactTipology}`}>{contactTipology}</label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="col-md-6 row flex-column align-items-center justify-content-center">
                    <label className="font-weight-bold">Prevalenza collegamento</label>
                    <div>
                        {linkPrevalences.map((contactTipology) => (
                            <div className="form-check form-check-inline" key={`checkbox_${contactTipology}`}>
                                <input className="form-check-input" type="checkbox"
                                       id={`checkbox_${contactTipology}`}
                                       checked={state.data.link_prevalence && state.data.link_prevalence.includes(contactTipology) || false}
                                       name="contact_tipology" value={contactTipology}
                                       onChange={changeDataPrevalenceLink}/>
                                <label className="form-check-label"
                                       htmlFor={`checkbox_${contactTipology}`}>{contactTipology}</label>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
            <hr/>
            <div className="float-right">
                <button className="btn btn-danger mr-5">
                    ANNULLA
                </button>
                <button type="submit" className="btn btn-success" disabled={props.isSubmitting}>
                    {
                        props.isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
                        ) : "CONFERMA"
                    }
                </button>
            </div>
        </form>
    )
}

export default FormActivity
